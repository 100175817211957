import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import {
  closeSplashDialog,
  showSplashDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import useQueryParam from 'hooks/useQueryParam';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getErrorMessageKey } from 'utils/getErrorMessageKey';
import AuthService from '../../../../services/auth.api';

interface ResetPasswordInputs {
  password: string;
  confirmPassword: string;
}

export const useResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const token = useQueryParam('token');
  const [inputValues, setInputValues] = useState<ResetPasswordInputs>({
    password: '',
    confirmPassword: '',
  });

  const validatePasswords = useCallback(() => {
    if (!inputValues.password || !inputValues.confirmPassword) {
      setError('reset_password.error.required_fields');
      return false;
    }

    if (inputValues.password.length < 8) {
      setError('reset_password.error.password_length');
      return false;
    }

    if (inputValues.password !== inputValues.confirmPassword) {
      setError('reset_password.error.passwords_not_match');
      return false;
    }

    return true;
  }, [inputValues.password, inputValues.confirmPassword]);

  const resetPassword = useCallback(async () => {
    if (!validatePasswords()) {
      return;
    }

    if (!token) {
      setError('reset_password.error.invalid_token');
      return;
    }

    setLoading(true);
    dispatch(showSplashDialog({ title: 'dialogs.splash.resetting_password' }));
    if (error) {
      setError('reset_password.error.invalid_token');
      return;
    }

    try {
      await AuthService.resetPassword(token, inputValues.password);
      navigate('/auth/sign-in?success=password_reset');
      toast({
        title: t('reset_password.password_changed_successfully'),
        status: 'success',
        variant: 'green',
      });
    } catch (e) {
      const error = e as AxiosError;
      const errorMessageKey = getErrorMessageKey(error.response?.status || 400);

      setError(errorMessageKey);
    } finally {
      dispatch(closeSplashDialog());
      setLoading(false);
    }
  }, [
    validatePasswords,
    token,
    dispatch,
    error,
    inputValues.password,
    navigate,
    toast,
    t,
  ]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setInputValues((prevState) => ({ ...prevState, [name]: value }));
      setError(''); // Clear error when user types
    },
    [],
  );

  return {
    handleChange,
    inputValues,
    resetPassword,
    loading,
    error,
    setError,
  };
};
