import { Flex, Spinner } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { setEventResultFilter } from 'contexts/redux/event/eventSlice';
import { AppDispatch } from 'contexts/redux/store';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import EventsOverviewHeader from './components/EventsOverviewHeader';
import GridEventCard from './components/GridEventCard';
import ListEventCard from './components/ListEventCard';
import NoEvents from './components/NoEvents';
import NoEventsCreated from './components/NoEventsCreated';
import useEvents from './hooks/useEvents';
const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};
export default function EventsOverview({
  isArchive = false,
}: {
  isArchive?: boolean;
}) {
  const [activeLayout, setActiveLayout] = useState<'grid' | 'list'>('grid');
  const { result, isFilterEmpty, isLoading } = useEvents();
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (isArchive) {
      dispatch(setEventResultFilter({ status: ['completed', 'cancelled'] }));
    } else {
      dispatch(setEventResultFilter({ status: ['new', 'active', 'post'] }));
    }
  }, [dispatch, isArchive]);
  return (
    <Flex
      w="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}
      flexDirection={{ base: 'column', md: 'row' }}
      gap={4}>
      <Card
        w="100%"
        h="calc(100vh - 170px)"
        gap="28px"
        borderRadius={{ base: '0px', md: '18px' }}>
        <EventsOverviewHeader
          activeLayout={activeLayout}
          isArchive={isArchive}
          setActiveLayout={setActiveLayout}
        />
        <AnimatePresence mode="wait">
          {isLoading ? (
            <Flex h="100%" w="100%" justifyContent="center" alignItems="center">
              <Spinner size="xl" />
            </Flex>
          ) : (
            <motion.div
              variants={container}
              initial="hidden"
              style={{
                width: 'inherit',
                height: 'inherit',
                overflow: 'auto',
              }}
              animate="visible">
              {result.length === 0 && !isFilterEmpty && <NoEvents />}
              {result.length === 0 && isFilterEmpty && <NoEventsCreated />}
              {result.length > 0 && (
                <motion.div
                  variants={item}
                  style={{ width: 'inherit', height: 'inherit' }}>
                  <Flex
                    wrap={activeLayout === 'list' ? 'nowrap' : 'wrap'}
                    flexDirection={activeLayout === 'list' ? 'column' : 'row'}
                    overflowY="auto"
                    gap="12px">
                    {result.map((event, index) =>
                      activeLayout === 'list' ? (
                        <ListEventCard key={index} event={event} />
                      ) : (
                        <GridEventCard key={index} event={event} />
                      ),
                    )}
                  </Flex>
                </motion.div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </Card>
    </Flex>
  );
}
