import { Flex, Icon, IconButton } from '@chakra-ui/react';
import { FiGrid } from 'react-icons/fi';
import { IoIosList } from 'react-icons/io';

interface LayoutSelectorProps {
  activeLayout: 'list' | 'grid';
  onLayoutChange?: (layout: 'grid' | 'list') => void;
}
export default function LayoutSelector(props: LayoutSelectorProps) {
  const { activeLayout, onLayoutChange } = props;
  return (
    <Flex
      gap="12px"
      border="1px solid #0000001A"
      borderRadius="16px"
      p="8px 12px">
      <IconButton
        aria-label=""
        transition={'background-color 0.3s linear'}
        bg={activeLayout === 'list' ? '#93C3B333' : 'transparent'}
        icon={<Icon as={IoIosList} color="#666666" w="18px" h="18px" />}
        onClick={() => onLayoutChange('list')}
        borderRadius="8px"
        w="32px"
        minW="32px"
        h="32px"
      />
      <IconButton
        aria-label=""
        transition={'background-color 0.3s linear'}
        bg={activeLayout === 'grid' ? '#93C3B333' : 'transparent'}
        onClick={() => onLayoutChange('grid')}
        borderRadius="8px"
        w="32px"
        minW="32px"
        h="32px"
        icon={<Icon as={FiGrid} color="#93C3B3" w="18px" h="18px" />}
      />
    </Flex>
  );
}
