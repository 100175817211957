// Chakra imports
import {
  CircularProgress,
  Flex,
  FlexProps,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { MdInfoOutline } from 'react-icons/md';
// Custom components

import SwitchField from 'components/fields/SwitchField';
import { CheckboxItem } from 'views/main/theOffice/team/components/CheckboxItem';
import { RadioButtonItem } from './RadioItem';

export interface InputFieldProps extends InputProps {
  id?: string;
  isDisabled?: boolean;
  label?: string;
  tooltipLabel?: string;
  switchProps?: {
    id: string;
    size: 'sm' | 'md' | 'lg';
    isChecked: boolean;
    onChange: () => void;
    labelColor?: string;
    label: string;
  };
  extra?: JSX.Element | string;
  errorLabel?: string;
  placeholder?: string;
  type?: string;
  onRadioButtonChecked?: (value: string) => void;
  radioButtonChecked?: boolean;
  showRadioButton?: boolean;
  iconPointerEvents?: 'none' | 'auto' | 'all' | 'inherit' | 'initial' | 'unset';
  containerStyles?: FlexProps;
  [x: string]: any;
}
export default function Default(props: InputFieldProps) {
  const {
    id,
    label,
    errorLabel,
    tooltipLabel,
    switchProps,
    extra,
    value,
    placeholder,
    iconLeft,
    iconRight,
    _placeholder,
    iconStyle,
    isLoading,
    type,
    showRadioButton,
    radioButtonChecked,
    onRadioButtonChecked,
    w,
    mb,
    labelProps,
    iconPointerEvents,
    containerStyles,
    ...rest
  } = props;
  // Chakra Color Mode

  const textColorPrimary = useColorModeValue('primary.100', 'white');
  const bgInput = useColorModeValue('white', 'brand.300');
  const {
    isOpen: isOpenTooltip,
    onOpen: onOpenTooltip,
    onClose: onCloseTooltip,
    onToggle: onToggleTooltip,
  } = useDisclosure();

  const { t, i18n } = useTranslation();
  const _iconLeft = i18n.dir() === 'ltr' ? iconLeft : iconRight;
  const _iconRight = i18n.dir() === 'ltr' ? iconRight : iconLeft;
  return (
    <Flex direction="column" mb={mb} w={w} {...containerStyles}>
      <HStack justify={'start'} align="center" w="100%">
        {isLoading && (
          <CircularProgress isIndeterminate color="brand.900" size="14px" />
        )}
        {showRadioButton && (
          <RadioButtonItem
            value={id}
            opacity={1}
            h={10}
            label={''}
            w="fit-content"
            ms="0px"
            checked={radioButtonChecked}
            onChange={onRadioButtonChecked}
          />
        )}
        <FormLabel
          display={label || extra ? 'flex' : 'none'}
          htmlFor={id}
          w="100%"
          mb={1}
          onClick={onRadioButtonChecked && (() => onRadioButtonChecked(id))}
          fontSize="sm"
          color={textColorPrimary}
          fontWeight="medium"
          _hover={{ cursor: 'pointer' }}
          {...labelProps}
          opacity={showRadioButton && 1}>
          {label}
          <Text fontSize="sm" fontWeight="400" ms="2px">
            {extra}
          </Text>
        </FormLabel>
        {switchProps && <SwitchField {...switchProps} fontSize="sm" />}
      </HStack>
      <InputGroup>
        {_iconLeft && (
          <InputLeftElement
            h={'48px'}
            pointerEvents={iconPointerEvents ? iconPointerEvents : 'none'}
            color="gray.300"
            {...iconStyle}>
            {_iconLeft}
          </InputLeftElement>
        )}
        <Input
          type={type}
          id={id}
          value={value}
          bg={props.disabled ? 'gray.150' : bgInput}
          //   in order to make it work for both LTR and RTL languages you should add the following line:
          color={textColorPrimary}
          fontWeight="500"
          pl={_iconLeft ? '34px' : '20px'}
          pr={_iconRight ? '34px' : '20px'}
          variant="main"
          placeholder={placeholder}
          fontSize="md"
          _active={{
            borderColor: 'brand.600',
          }}
          _focus={{
            borderColor: 'brand.600',
          }}
          _placeholder={{
            fontWeight: '400',
            color: 'secondaryGray.600',
            ..._placeholder,
          }}
          _disabled={{
            cursor: 'not-allowed',
          }}
          h="50px"
          maxH="50px"
          {...rest}
        />
        {_iconRight && (
          <InputRightElement
            pointerEvents={iconPointerEvents ? iconPointerEvents : 'none'}
            color="gray.300"
            h="48px"
            {...iconStyle}>
            {_iconRight}
          </InputRightElement>
        )}
        {tooltipLabel && (
          <InputRightElement>
            <Tooltip
              variant="business"
              hasArrow
              isOpen={isOpenTooltip}
              label={tooltipLabel}
              placement="top">
              <span>
                <Icon
                  onMouseEnter={onOpenTooltip}
                  onMouseLeave={onCloseTooltip}
                  onClick={onToggleTooltip}
                  as={MdInfoOutline}
                  color="brand.400"
                  fontSize="24px"
                  cursor="pointer"
                  mt={4}
                  ms="2px"
                  aria-label={''}
                />
              </span>
            </Tooltip>
          </InputRightElement>
        )}
      </InputGroup>
      {errorLabel && (
        <Text fontSize="xs" fontWeight={'semibold'} color="red.500" mt="2px">
          {t(errorLabel)}
        </Text>
      )}
    </Flex>
  );
}
