import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import {
  closeSplashDialog,
  showSplashDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import useQueryParam from 'hooks/useQueryParam';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getErrorMessageKey } from 'utils/getErrorMessageKey';
import AuthService from '../../../../services/auth.api';
const emailRegexValidation = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

export const useForgotPassword = () => {
  const [loading, setLoading] = useState(false);

  const errorParam = useQueryParam('error');
  const toast = useToast();
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = useState<Record<string, string>>();

  const sendResetPasswordEmail = useCallback(async () => {
    setError('');
    if (!emailRegexValidation.test(inputValues?.email)) {
      setError('Invalid email address');
      return;
    }

    setLoading(true);

    dispatch(
      showSplashDialog({
        title: 'dialogs.splash.sending_reset_password_email',
      }),
    );
    setTimeout(async () => {
      try {
        await AuthService.forgotPassword(inputValues?.email);
        toast({
          title: t('forgot_password.email_sent'),
          status: 'success',
          variant: 'green',
        });
      } catch (e) {
        const error = e as AxiosError;
        const data = error.response?.data as { message: string };
        console.log(data);
        if (data.message === 'google_account_cannot_reset_password') {
          setError('reset_password.error.google_account_cannot_reset_password');
        } else if (error.response?.status === 401) {
          setError('forgot_password.error.invalid_email');
        } else {
          const errorMessageKey = getErrorMessageKey(
            error.response?.status || 400,
          );
          setError(errorMessageKey);
        }
      }

      dispatch(closeSplashDialog());
    }, 1000);

    setLoading(false);
  }, [dispatch, inputValues?.email]);

  const signInWithGoogle = useCallback(async () => {
    AuthService.logout();
    AuthService.signInWithGoogle();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputValues((prevState) => ({ ...prevState, [name]: value }));
  };

  return {
    handleChange,
    signInWithGoogle,
    inputValues,
    sendResetPasswordEmail,
    loading,
    setLoading,
    error,
    errorParam,
    setError,
  };
};
