export enum AppNotificationEventName {
  // events
  EventDuePassed = 'eventDuePassed',
  EventNewEvent = 'newEvent',
  EventStatusChange = 'eventStatusChange',
  EventRSVPUpdates = 'rsvpUpdates',
  EventTaskDeadlines = 'taskDeadlines',
  EventSeatingChanges = 'seatingChanges',

  // finances
  FinanceNewInvoice = 'newInvoice',
  FinanceBudgetUpdate = 'budgetUpdate',
  FinancePaymentReceived = 'paymentReceived',
  FinancePaymentPending = 'paymentPending',
  FinanceSmartContractMilestone = 'smartContractMilestone',

  // suppliers
  SupplierInvitation = 'supplierInvitation',
  SupplierResponse = 'supplierResponse',
  SupplierAvailabilityUpdate = 'supplierAvailabilityUpdate',
  SupplierPriceOffer = 'supplierPriceOffer',

  // tasks
  TaskAssignment = 'taskAssignment',
  TaskStatusChange = 'taskStatusChange',
  TaskCalendarReminder = 'taskCalendarReminder',

  // guests
  GuestRSVPChanges = 'guestRSVPChanges',
  GuestSpecialRequests = 'guestSpecialRequests',
  GuestRSVPReminder = 'guestRSVPReminder',

  // messages
  MessageNew = 'newMessage',
  MessageUnread = 'unreadMessage',
  MessageSystemBroadcast = 'systemBroadcast',

  // documents && contracts
  DocumentShared = 'documentShared',
  DocumentUpdated = 'documentUpdated',
  ContractSent = 'contractSent',
  ContractSigned = 'contractSigned',
  ContractRejected = 'contractRejected',

  // system
  SystemNewUser = 'systemNewUser',
  SystemSecurityAlert = 'systemSecurityAlert',
  SystemDataBackupConfirmation = 'systemDataBackupConfirmation',

  // quotes
  QuoteCreated = 'quoteCreated',
  QuoteUpdated = 'quoteUpdated',
  QuoteRequest = 'quoteRequest',

  // meetings
  MeetingNewMeeting = 'meetingNewMeeting',
  MeetingInvite = 'meetingInvite',

  // from document
  NotificationNewAccount = 'notificationNewAccount',
  NotificationConfirmEmail = 'notificationConfirmEmail',
  NotificationSupplierInvitationSend = 'notificationSupplierInvitationSend',
  NotificationSupplierInvitationResponse = 'notificationSupplierInvitationResponse',
  NotificationForgotPassword = 'notificationForgotPassword',
  NotificationResetPasswordSuccess = 'notificationResetPasswordSuccess',
  NotificationPaymentSuccess = 'notificationPaymentSuccess',
  NotificationPaymentDeclined = 'notificationPaymentDeclined',
  NotificationPaymentMethodUpdate30Days = 'notificationPaymentMethodUpdate30Days',
  NotificationPaymentMethodUpdate7Days = 'notificationPaymentMethodUpdate7Days',
  NotificationPaymentMethodUpdate48Hours = 'notificationPaymentMethodUpdate48Hours',
  NotificationPaymentMethodUpdate24Hours = 'notificationPaymentMethodUpdate24Hours',
  NotificationPaymentMethodUpdate0Hours = 'notificationPaymentMethodUpdate0Hours',
  NotificationNewSeatInvitationRecipient = 'notificationNewSeatInvitationRecipient',
  NotificationNewSeatInvitationSender = 'notificationNewSeatInvitationSender',
  NotificationCalendarAvailabilityRequest = 'notificationCalendarAvailabilityRequest',
  NotificationNewEventCreated = 'notificationNewEventCreated',
  NotificationEventStatusChange = 'notificationEventStatusChange',
  NotificationRSVPUpdates = 'notificationRSVPUpdates',
  NotificationTasksDeadline = 'notificationTasksDeadline',
  NotificationSeatingArrangementChange = 'notificationSeatingArrangementChange',
  NotificationQuoteRequestSend = 'notificationQuoteRequestSend',
  NotificationQuoteRequestResponse = 'notificationQuoteRequestResponse',
  NotificationQuoteRequestResponseYes = 'notificationQuoteRequestResponseYes',
  NotificationQuoteRequestResponseNo = 'notificationQuoteRequestResponseNo',
  NotificationQuoteRequestResponseReminder = 'notificationQuoteRequestResponseReminder',
  NotificationEventStatusUpdate = 'notificationEventStatusUpdate',
  NotificationReminders = 'notificationReminders',
  NotificationCalendarReminder = 'notificationCalendarReminder',
  NotificationRSVPChange = 'notificationRSVPChange',
  NotificationNewMessage = 'notificationNewMessage',
  NotificationUnreadMessage = 'notificationUnreadMessage',
  NotificationSystemUpdates = 'notificationSystemUpdates',
  NotificationDocShared = 'notificationDocShared',
  NotificationContractSigned = 'notificationContractSigned',
  NotificationDocEdited = 'notificationDocEdited',
  NotificationPaymentReceived = 'notificationPaymentReceived',
}
