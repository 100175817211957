import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import {
  AppDialogProps,
  closeQuoteSharingDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

export default function QuoteSharingDialog() {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const toast = useToast();
  const { quoteSharingDialog } = useSelector((state: RootState) => ({
    quoteSharingDialog: state.dialogs.quoteSharingDialog,
  }));
  const dialog = quoteSharingDialog as AppDialogProps;

  const handleClose = useCallback(() => {
    dispatch(closeQuoteSharingDialog());
  }, [dispatch]);

  const handleSend = useCallback(() => {
    console.log('send');
    handleClose();
    toast({
      title: t('quote_sharing_dialog.sent'),
      variant: 'main',
      position: 'top-right',
      status: 'success',
    });
  }, [handleClose, toast, t]);

  return (
    <Modal isOpen={!!dialog} onClose={handleClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent borderRadius="30px" overflow="hidden">
        <ModalBody p="24px" pt={0}>
          <Flex gap="24px" flexDir="column">
            <HStack justify="space-between" align="center">
              <Text variant="cardTitle">{t('quote_sharing_dialog.title')}</Text>
              <IconButton
                icon={<Icon as={MdClose} color="#939393" w="24px" h="24px" />}
                aria-label="close"
                variant="ghost"
                onClick={handleClose}
              />
            </HStack>
            <InputField
              placeholder={t('quote_sharing_dialog.to_placeholder')}
              label={t('quote_sharing_dialog.to')}
              labelProps={{
                fontSize: '14px',
                fontWeight: '500',
                color: '#00000099',
                ps: '10px',
              }}
            />
            <HStack alignItems="end" gap="12px">
              <InputField
                w="100%"
                flex="1"
                placeholder={t('quote_sharing_dialog.cc_placeholder')}
                label={t('quote_sharing_dialog.cc')}
                labelProps={{
                  fontSize: '14px',
                  fontWeight: '500',
                  color: '#00000099',
                  ps: '10px',
                }}
              />
              <Icon
                as={FiPlus}
                bg="brand.400"
                w="32px"
                h="32px"
                cursor="pointer"
                color="white"
                borderRadius="8px"
                onClick={() => {}}
              />
            </HStack>
            <Button
              w="280px"
              variant="h1cta"
              alignSelf="center"
              onClick={handleSend}>
              {t('quote_sharing_dialog.send')}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
