import { Button, Flex, Image, Text } from '@chakra-ui/react';
import chatsEmptyIcon from 'assets/svg/messenger/chats-empty-icon.svg';
import { useTranslation } from 'react-i18next';
import { LuPencilLine } from 'react-icons/lu';
import Card from '../../../../../components/card/Card';
interface NoMessagesYetProps {
  onStartConversation?: () => void;
}

export default function NoMessagesYet({
  onStartConversation,
}: NoMessagesYetProps) {
  const { t } = useTranslation();
  return (
    <Flex
      w="100%"
      h="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}
      flexDirection="column"
      gap={4}>
      <Card h="100%" justifyContent={'center'} alignItems="center">
        <Image
          src={chatsEmptyIcon}
          w={90}
          h={70}
          alt="No Conversation Found"
          mb={14}
        />
        <Text fontSize={'2xl'} fontWeight="bold">
          {t('messenger.no_messages_found')}
        </Text>

        <Text mt={6} fontSize="sm" color="primary.100">
          {t('messenger.no_messages_found_description')}
        </Text>
        <Button
          borderRadius={'full'}
          onClick={onStartConversation}
          mt={120}
          variant={'h1cta'}
          rightIcon={<LuPencilLine />}>
          {t('messenger.start_conversation')}
        </Button>
      </Card>
    </Flex>
  );
}
