import { Flex, Hide, Icon, IconButton, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IoFunnelOutline } from 'react-icons/io5';
import { MdKeyboardArrowDown } from 'react-icons/md';
export default function MobileFilterBurger(props: {
  onToggle: () => void;
  isOpen: boolean;
}) {
  const { onToggle, isOpen } = props;
  const { t } = useTranslation();
  return (
    <Hide above="md">
      <Flex
        onClick={onToggle}
        w="100%"
        alignItems="center"
        justifyContent="space-between">
        <Flex gap="12px" alignItems="center">
          <IconButton
            borderRadius="8px"
            w="32px"
            h="32px"
            minW="32px"
            maxW="32px"
            aria-label=""
            icon={<IoFunnelOutline color="#93C3B3" />}
            bg="#93C3B333"
          />
          <Text variant="mobileFilterBurger">
            {t('events_overview.filters')}
          </Text>
        </Flex>
        <Icon
          as={MdKeyboardArrowDown}
          color="#939393"
          w="20px"
          h="20px"
          transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
          transition="all 0.2s linear"
        />
      </Flex>
    </Hide>
  );
}
