import {
  Box,
  Button,
  Flex,
  FlexProps,
  FormLabel,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  useColorModeValue,
  useDimensions,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
interface TimeSelectorProps extends Omit<FlexProps, 'onChange'> {
  label?: string;
  id?: string;
  value?: string;
  borderColor?: string;
  onChange?: (value: string) => void;
}
const TimeSelector = (props: TimeSelectorProps) => {
  const { label, id, borderColor, value, onChange, ...rest } = props;
  const textColorPrimary = useColorModeValue('primary.100', 'white');
  const [hour, setHour] = React.useState(parseInt(value.split(':')[0]));
  const [minute, setMinute] = React.useState(parseInt(value.split(':')[1]));
  const { i18n } = useTranslation();

  const dropdownContainerRef = useRef<HTMLDivElement>(null);
  const dimensions = useDimensions(dropdownContainerRef);
  const { isOpen, onClose, onToggle } = useDisclosure();

  const hours = Array.from({ length: 24 }, (_, i) => i);
  const minutes = Array.from({ length: 60 / 5 }, (_, i) => i * 5);

  const flexDirection = useMemo(
    () => (i18n.language === 'he' ? 'row-reverse' : 'row'),
    [i18n.language],
  );

  const handleSelectHour = (h: any) => {
    setHour(h);
    onChange(
      `${String(h).padStart(2, '0')}:${String(minute).padStart(2, '0')}`,
    );
  };

  const handleSelectMinute = (m: any) => {
    setMinute(m);
    onChange(`${String(hour).padStart(2, '0')}:${String(m).padStart(2, '0')}`);
  };

  return (
    <Flex flexDirection="column" ref={dropdownContainerRef} {...rest}>
      <HStack align="center">
        <FormLabel
          display={label ? 'flex' : 'none'}
          htmlFor={id}
          mb={1}
          fontSize="sm"
          color={textColorPrimary}
          fontWeight="medium"
          _hover={{ cursor: 'pointer' }}>
          {label}
        </FormLabel>
      </HStack>
      <Menu isOpen={isOpen}>
        <MenuButton
          as={Button}
          variant="time"
          onClick={onToggle}
          borderColor={borderColor}>
          {`${String(hour).padStart(2, '0')}:${String(minute).padStart(
            2,
            '0',
          )}`}
        </MenuButton>

        <MenuList
          onBlur={onClose}
          border="1px solid #0000004D"
          borderRadius="16px"
          minW={dimensions?.contentBox?.width}
          zIndex="99"
          pt="19px"
          pb="3px">
          <Flex flexDirection={flexDirection} justifyContent="center" gap="3px">
            <Box overflowY="scroll" h="150px" position="relative">
              <Flex flexDirection="column" gap="16px">
                {hours.map((h) => (
                  <Box
                    key={h}
                    onClick={() => handleSelectHour(h)}
                    cursor="pointer"
                    textAlign="center"
                    color={hour === h ? '#000000CC' : '#00000066'}
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="15px"
                    letterSpacing="-2%">
                    {String(h).padStart(2, '0')}
                  </Box>
                ))}
              </Flex>
            </Box>

            <Box
              alignSelf="start"
              fontSize="14px"
              fontWeight="500"
              lineHeight="15px"
              letterSpacing="-2%">
              :
            </Box>

            <Box overflowY="scroll" h="150px" position="relative">
              <Flex flexDirection="column" gap="16px">
                {minutes.map((m) => (
                  <Box
                    key={m}
                    onClick={() => handleSelectMinute(m)}
                    cursor="pointer"
                    textAlign="center"
                    color={minute === m ? '#000000CC' : '#00000066'}
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="15px"
                    letterSpacing="-2%">
                    {String(m).padStart(2, '0')}
                  </Box>
                ))}
              </Flex>
            </Box>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default TimeSelector;
