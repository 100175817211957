import { useCallback, useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'contexts/redux/store';
import { Supplier } from 'services/@types';

export const useFilteredSuppliers = () => {
  const [filteredSuppliers, setFilteredSuppliers] = useState<Supplier[]>([]);
  const [filteringCategories, setFilteringCategories] = useState<string[]>([]);
  const [filterFavorites, setFilterFavorites] = useState<boolean>(false);
  const [filterMyPhonebook, setFilterMyPhonebook] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { suppliers } = useSelector((state: RootState) => state.suppliers);

  const filterBySearch = useCallback((supplier: Supplier, query: string) => {
    return supplier.name.toLowerCase().includes(query.toLowerCase());
  }, []);

  const filterByCategories = useCallback(
    (supplier: Supplier, categories: string[]) => {
      return (
        categories.length === 0 ||
        categories.some((category) => supplier.category.includes(category))
      );
    },
    [],
  );

  const filterByFavorites = useCallback(
    (supplier: Supplier, isFavoriteFilter: boolean) => {
      return !isFavoriteFilter || supplier.isFavorite;
    },
    [],
  );

  const filterByPhonebook = useCallback(
    (supplier: Supplier, isPhonebookFilter: boolean) => {
      return !isPhonebookFilter || false; //supplier.isInPhonebook;
    },
    [],
  );

  useEffect(() => {
    const filtered = suppliers.filter((supplier) => {
      const matchesSearch = filterBySearch(supplier, searchQuery);
      const matchesCategories = filterByCategories(
        supplier,
        filteringCategories,
      );
      const matchesFavorites = filterByFavorites(supplier, filterFavorites);
      const matchesPhonebook = filterByPhonebook(supplier, filterMyPhonebook);

      return (
        matchesSearch &&
        matchesCategories &&
        matchesFavorites &&
        matchesPhonebook
      );
    });

    setFilteredSuppliers(filtered);
  }, [
    filteringCategories,
    suppliers,
    searchQuery,
    filterFavorites,
    filterMyPhonebook,
    filterBySearch,
    filterByCategories,
    filterByFavorites,
    filterByPhonebook,
  ]);

  return {
    filteredSuppliers,
    setFilteringCategories,
    setFilterFavorites,
    setFilterMyPhonebook,
    filteringCategories,
    suppliers,
    setSearchQuery,
  };
};
