// Chakra imports
import { Flex, Hide, Icon, IconButton, Image, Show } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAuthDefault';
import PropTypes from 'prop-types';
// Assets
import { LanguagePicker } from 'components/languagePicker/LanguagePicker';
import { useTranslation } from 'react-i18next';
import { FaX } from 'react-icons/fa6';

function AuthIllustration(props: {
  children: JSX.Element;
  illustrationBackground?: string;
  image: string;
}) {
  const { children, image } = props;
  // Chakra color mode
  const { i18n } = useTranslation();
  return (
    <Flex
      h={{ base: undefined, lg: '100dvh' }}
      flexDirection={{
        base: 'column',
        sm: 'column',
        md: 'column',
        lg: 'row',
        xl: 'row',
      }}
      position="relative">
      <Show below="lg" above="sm">
        <Image
          objectFit="cover"
          h={'20vh'}
          borderBottomLeftRadius="220px"
          src={image}
          objectPosition={'bottom right'}
          alt="illustration"
          w="100%"
        />
      </Show>
      <Flex
        flex={1}
        w="100%"
        pt={{ sm: '10px', md: '0px' }}
        px={{ md: '20xp', lg: '0px' }}
        justifyContent="start"
        direction="column">
        {children}
        <Hide below="lg">
          <Flex
            // display={{ base: 'none', md: 'block', sm: 'none' }}
            h="100%"
            w={{ lg: '50vw' }}
            position="absolute"
            left={i18n.dir() === 'rtl' ? '0' : 'auto'}
            right={i18n.dir() === 'rtl' ? 'auto' : '0'}
            transform={i18n.dir() === 'rtl' ? 'rotateY(-180deg)' : ''}
            borderBottomLeftRadius={{ lg: '120px', xl: '200px' }}>
            <Image
              src={image}
              h="100%"
              w="100%"
              objectFit="cover"
              borderBottomLeftRadius={{ lg: '120px', xl: '200px' }}
            />
          </Flex>
        </Hide>

        <Flex
          top={10}
          left={i18n.dir() !== 'rtl' ? 'auto' : '10px'}
          right={i18n.dir() !== 'rtl' ? '10px' : 'auto'}
          position="absolute"
          me={{
            base: '20px',
            md: '44px',
          }}
          gap="17px">
          <LanguagePicker />
          <IconButton
            h="34px"
            maxW="34px"
            minW="34px"
            bg="transparent"
            aria-label="close"
            color="#00000066"
            icon={<Icon as={FaX} />}
            onClick={() => window.location.replace('https://brillevent.com')}
          />
        </Flex>
        <Footer />
      </Flex>
    </Flex>
  );
}
// PROPS

AuthIllustration.propTypes = {
  image: PropTypes.any,
};

export default AuthIllustration;
