import { Flex, HStack, Image, Text } from '@chakra-ui/react';
import BrilliantIcon from 'assets/svg/notification/brilliant.svg';
import moment from 'moment';
import 'moment/locale/he';
import { useTranslation } from 'react-i18next';
import { INotification } from 'services/@types';
import { getNotificationMomentFormat } from './NotificationComponent';

export default function SystemNotification({
  notification,
  notificationRef,
}: {
  notification: INotification;
  notificationRef: React.RefObject<HTMLDivElement>;
}) {
  const { t, i18n } = useTranslation();

  // Set moment locale based on current language
  moment.locale(i18n.language.startsWith('he') ? 'he' : 'en');

  return (
    <Flex
      ref={notificationRef}
      border="1px solid #F9FAFB"
      borderRadius="12px"
      p="8px"
      flexDirection="column">
      <HStack gap="12px">
        <Image src={BrilliantIcon} alt="" w="25px" h="auto" />
        <Text variant="cardSubTitle" color="#1B2037">
          {/* We just added a new feature!{' '} */}
          {t(notification.message)}
          <Text
            variant="eventLink"
            color="#AA915D"
            onClick={() => {
              window.open(notification.messageCaptionLink, '_blank');
            }}>
            {/* come check it out */}
            {t(notification.messageCaptionLinkText)}
          </Text>
        </Text>
      </HStack>
      <Text fontSize="md" color="gray.500" alignSelf="flex-end">
        {moment(notification.createdAt).format(
          getNotificationMomentFormat(i18n.language),
        )}
      </Text>
    </Flex>
  );
}
