import {
  Collapse,
  Flex,
  Image,
  Switch,
  Text,
  useDisclosure,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import Card from 'components/card/Card';
import { RootState } from 'contexts/redux/store';
import { fetchTrackedEvents } from 'contexts/redux/trackedEvent/trackedEventSlice';
import noNotes from 'assets/img/tracker/noNotes.png';
import noNotesMobile from 'assets/img/tracker/noNotesMobile.png';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TrackItem from './TrackItem';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';

export default function Tracker() {
  const { t, i18n } = useTranslation();
  const { isOpen, onToggle } = useDisclosure();
  const dispatch = useDispatch<any>();
  const params = useParams();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { trackedEvents } = useSelector(
    (state: RootState) => state.trackedEvent,
  );
  const { style, ref } = useHighlightAnimation('tracker');

  useQuery({
    queryKey: ['trackedEvents', params?.id],
    queryFn: () =>
      dispatch(
        fetchTrackedEvents({ eventId: params?.id, projectBy: '-updatedAt' }),
      ),
    enabled: !!params?.id,
  });

  return (
    <Card
      ref={ref}
      {...style}
      w={{ base: '100%' }}
      h="100%"
      flex={1}
      gap="28px"
      borderRadius={{ base: '0px', md: '18px' }}>
      <Flex alignItems="center" justifyContent="space-between">
        <Text variant="cardTitle">{t('tracker.title')}</Text>
        <Flex alignItems="center" gap="8px">
          <Text variant="eventSubTitle" fontSize="14px">
            {t('tracker.show_notes')}
          </Text>
          <Switch
            dir={i18n.dir()}
            variant="brand"
            checked={isOpen}
            onChange={onToggle}
          />
        </Flex>
      </Flex>
      <Flex flexDirection="column" gap="5px" overflowY="auto">
        {trackedEvents?.map((trackedEvent, index) => (
          <TrackItem
            trackedEvent={trackedEvent}
            key={index}
            isLast={trackedEvents.length - 1 === index}
            isNoteVisible={isOpen}
          />
        ))}
        <Collapse
          in={
            isOpen &&
            trackedEvents.length === 1 &&
            trackedEvents[0].notes?.length === 0
          }
          animateOpacity>
          <VStack
            w="100%"
            justifyContent="center"
            alignItems="center"
            textAlign="center">
            {isMobile && (
              <Image src={noNotesMobile} alt="no notes" maxW="110px" />
            )}
            {!isMobile && <Image src={noNotes} alt="no notes" maxW="120px" />}
            <Text variant="eventSubTitle">{t('tracked_event.no_notes')}</Text>
            <Text variant="eventTabButtonTitle" maxW="70%">
              {t('tracked_event.no_notes_desc')}
            </Text>
          </VStack>
        </Collapse>
      </Flex>
    </Card>
  );
}
