import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import _threadService from 'services/thread.api';

export const useAllUnreadMessagesCount = (
  lastMessageId: string | null,
): UseQueryResult<{
  unreadMessagesCount: number;
}> => {
  return useQuery({
    queryKey: ['unreadAllMessagesCount', lastMessageId],
    queryFn: () => _threadService.getAllUnreadMessagesCount(),
    cacheTime: 500,
    staleTime: 500,
  } as UseQueryOptions<any>);
};

export const useUnreadMessagesCount = (
  threadId: string,
  lastMessage?: string | null,
): UseQueryResult<{
  threadId: string;
  unreadMessagesCount: number;
}> => {
  return useQuery({
    queryKey: ['unreadMessagesCount', threadId, lastMessage],
    queryFn: () => _threadService.getUnreadMessagesCount(threadId),
    staleTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });
};

export const useDecreaseUnreadMessagesCount = (threadId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const currentData = queryClient.getQueryData<{
        threadId: string;
        unreadMessagesCount: number;
      }>(['unreadMessagesCount', threadId]);

      if (currentData && currentData.unreadMessagesCount > 0) {
        const updatedData = {
          ...currentData,
          unreadMessagesCount: currentData.unreadMessagesCount - 1,
        };

        // Optimistically update the cache
        queryClient.setQueryData(
          ['unreadMessagesCount', threadId],
          updatedData,
        );
        return updatedData;
      }

      return currentData;
    },
    onError: (error, variables, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      queryClient.setQueryData(['unreadMessagesCount', threadId], context);
    },
    onSettled: () => {
      // Always refetch after error or success to ensure our local data is correct
      queryClient.invalidateQueries({
        queryKey: ['unreadMessagesCount', threadId],
      });
    },
  });
};
