const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then(function (registration) {
        console.log(
          '@@ Registration successful, scope is:',
          registration.scope,
        );

        registration.addEventListener('message', (event) => {
          console.log('@@ Message received : ', event);
        });
      })
      .catch(function (err) {
        console.log('Service worker registration failed, error:', err);
      });
  }
};

export { registerServiceWorker };
