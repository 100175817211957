import axios from 'axios';
import { useEffect } from 'react';

const TENANT_ID = process.env.REACT_APP_OUTLOOK_OAUTH_CALENDAR_TENANT_ID;
const CLIENT_ID = process.env.REACT_APP_OUTLOOK_OAUTH_CALENDAR_CLIENT_ID;
const AUTHORITY = `https://login.microsoftonline.com/common`;
const SCOPES =
  'https://graph.microsoft.com/User.Read  https://graph.microsoft.com/OnlineMeetings.ReadWrite https://graph.microsoft.com/Calendars.ReadWrite offline_access';
const AUTH_ENDPOINT = `${AUTHORITY}/oauth2/v2.0/authorize`;
const REDIRECT_URI = process.env.REACT_APP_OUTLOOK_OAUTH_CALENDAR_REDIRECT_URI;
const TOKEN_ENDPOINT = `${AUTHORITY}/oauth2/v2.0/token`;

const generateRandomString = (length: number) => {
  const charset =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    result += charset[randomIndex];
  }
  return result;
};

// Hash the code_verifier using SHA-256 and return the base64-url-encoded result
const generateCodeChallengeFromVerifier = async (verifier: any) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(verifier);
  const digest = await window.crypto.subtle.digest('SHA-256', data);
  return base64URLEncode(digest);
};

// Helper function to base64-url-encode the SHA-256 digest
const base64URLEncode = (buffer: any) => {
  const base64String = btoa(String.fromCharCode(...new Uint8Array(buffer)));
  return base64String
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
};

function redirectToApi({
  access_token,
  refresh_token,
  email,
}: {
  access_token: string;
  refresh_token: string;
  email: string;
}) {
  const userId = localStorage.getItem('token')
    ? JSON.parse(localStorage.getItem('token') as string)?.user?.id
    : null;
  window.location.href = `${process.env.REACT_APP_OUTLOOK_OAUTH_CALENDAR_GET_TOKEN_REDIRECT_URI}?access_token=${access_token}&userId=${userId}&refresh_token=${refresh_token}&email=${email}`;
}

const generateCodeChallenge = async () => {
  const verifier = generateRandomString(128); // Generate a random code_verifier
  // Store the verifier for token exchange
  localStorage.setItem('code_verifier', verifier);

  const challenge = await generateCodeChallengeFromVerifier(verifier); // Generate code_challenge
  return challenge;
};

export const useOutlookCalendarAuth = () => {
  const initiateAuth = async () => {
    const codeChallenge = await generateCodeChallenge();

    const authUrl = `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}&scope=${SCOPES}&response_mode=query&code_challenge=${codeChallenge}&code_challenge_method=S256`;

    window.open(authUrl, '_blank');
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location?.search);
    const code = urlParams.get('code');

    if (code) {
      const fetchUserEmail = async (token: string) => {
        try {
          const response = await axios.get(
            'https://graph.microsoft.com/v1.0/me',
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );

          return response.data.mail || response.data.userPrincipalName; // User's email address
        } catch (error) {
          console.error('Error fetching user email: ', error);
        }
      };
      const getToken = async (code: string) => {
        const formBody = new URLSearchParams({
          client_id: CLIENT_ID,
          grant_type: 'authorization_code',
          code: code,
          redirect_uri: REDIRECT_URI,
          code_verifier: localStorage.getItem('code_verifier'),
        }).toString();
        try {
          const response = await axios.post(TOKEN_ENDPOINT, formBody, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          });

          if (response?.data?.access_token && response?.data?.refresh_token) {
            const email = await fetchUserEmail(response?.data?.access_token);
            if (email) {
              redirectToApi({
                access_token: response?.data?.access_token,
                refresh_token: response?.data?.refresh_token,
                email: email,
              });
            }
          }
        } catch (error) {
          console.error('Error fetching tokens: ', error);
        }
      };
      getToken(code);
    }
  }, []);
  return { initiateAuth };
};
