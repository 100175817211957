import { Flex, Image, Text } from '@chakra-ui/react';
import Icon from 'assets/svg/notification/taskAssigned.svg';
import moment from 'moment';
import 'moment/locale/he';
import { useTranslation } from 'react-i18next';
import { INotification } from 'services/@types';
import { getNotificationMomentFormat } from './NotificationComponent';

export default function PlainIconNotification({
  notification,
  notificationRef,
}: {
  notification: INotification;
  notificationRef: React.RefObject<HTMLDivElement>;
}) {
  const { t, i18n } = useTranslation();

  // Set moment locale based on current language
  moment.locale(i18n.language.startsWith('he') ? 'he' : 'en');

  return (
    <Flex
      ref={notificationRef}
      // border="1px solid #F9FAFB"
      bg="#93C3B31A"
      borderRadius="12px"
      p="8px"
      flexDirection="column">
      <Flex alignItems="center" gap="12px">
        <Image src={Icon} alt="" w="20px" h="20px" />
        <Text variant="cardSubTitle" color="#1B2037">
          {/* You got a task assigned to you */}
          {t(notification.message)}
        </Text>
      </Flex>
      <Text fontSize="md" color="gray.500" alignSelf="flex-end">
        {moment(notification.createdAt).format(
          getNotificationMomentFormat(i18n.language),
        )}
      </Text>
    </Flex>
  );
}
