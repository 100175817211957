import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { INotification, PaginatedResults } from 'services/@types';
import notificationService from 'services/notification.api';
export interface NotificationState {
  error: string | null;
  notifications: INotification[];
  unreadNotifications: INotification[];
  notificationReceived: INotification | null;
  loading: boolean;
}

const initialState: NotificationState = {
  error: null,
  notifications: [],
  unreadNotifications: [],
  notificationReceived: null,
  loading: false,
};

export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async (_, { rejectWithValue }) => {
    try {
      const response = await notificationService.getNotifications();

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateNotification = createAsyncThunk<
  INotification,
  { notificationId: string; updates: Partial<INotification> }
>(
  'notifications/updateNotification',
  async ({ notificationId, updates }, { rejectWithValue }) => {
    try {
      const response = await notificationService.updateNotification(
        notificationId,
        updates,
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const markNotificationAsRead = createAsyncThunk(
  'notifications/markNotificationAsRead',
  async (notificationId: string, { rejectWithValue }) => {
    try {
      await notificationService.markNotificationAsRead(notificationId);
      return notificationId;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const onNewNotificationReceived = createAsyncThunk(
  'notifications/onNewNotificationReceived',
  async (notificationId: string, { rejectWithValue }) => {
    try {
      const response = await notificationService.getNotification(
        notificationId,
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    syncLocalUnreadNotifications: (state) => {
      const foundReadNotificationsFromUnread = state.unreadNotifications.filter(
        (u) => u.readAt,
      );
      state.notifications = [
        ...state.notifications,
        ...foundReadNotificationsFromUnread,
      ].sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      );
      state.unreadNotifications = [
        ...state.notifications,
        ...state.unreadNotifications,
      ]
        .filter((n) => !n.readAt)
        .sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = [
          ...state.notifications,
          ...action.payload.results.filter((n) => n.readAt),
        ];
        state.unreadNotifications = [
          ...state.unreadNotifications,
          ...action.payload.results.filter((n) => !n.readAt),
        ];
      })
      .addCase(markNotificationAsRead.pending, (state) => {
        state.loading = true;
      })
      .addCase(markNotificationAsRead.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.unreadNotifications[
          state.unreadNotifications.findIndex((n) => n.id === payload)
        ].readAt = new Date();
      })
      .addCase(markNotificationAsRead.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(onNewNotificationReceived.fulfilled, (state, { payload }) => {
        state.unreadNotifications = [...state.unreadNotifications, payload];
        state.notificationReceived = payload;
      })
      .addCase(updateNotification.fulfilled, (state, { payload }) => {
        if (typeof payload.visible === 'boolean' && payload.visible === false) {
          state.unreadNotifications = state.unreadNotifications.filter(
            (n) => n.id !== payload.id,
          );
          state.notifications = state.notifications.filter(
            (n) => n.id !== payload.id,
          );
        }
      })
      .addCase(updateNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(onNewNotificationReceived.pending, (state) => {
        state.loading = true;
      });
  },
});

export const { syncLocalUnreadNotifications } = notificationsSlice.actions;
export default notificationsSlice.reducer;
