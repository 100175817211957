import { BellIcon } from '@chakra-ui/icons';
import {
  HStack,
  Icon,
  RenderProps,
  Spacer,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { onNewMessageReceived } from 'contexts/redux/messenger/messengerSlice';
import { onNewNotificationReceived } from 'contexts/redux/notifications/notificationsSlice';
import { sendPushNotificationToken } from 'contexts/redux/pushNotificationToken/pushNotificationTokenSlice';
import { getMessaging } from 'firebase/messaging';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LuX } from 'react-icons/lu';
import { useDispatch } from 'react-redux';
import { getToken, messaging, onMessage } from '../../config/firebase';

export const NotifyAlertDialog: React.FC<
  {
    title: string;
    body: string;
    data: {
      type: string;
      threadId?: string;
      messageId?: string;
      senderName?: string;
    };
  } & RenderProps
> = ({ title, body, data, ...props }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const handleClick = useCallback(() => {
    toast.close(props.id);
    window.location.href = `/main/messenger/${data.threadId}`;

    toast.close(props.id);
  }, [data.threadId, props.id, toast]);
  return (
    <VStack
      onClick={handleClick}
      p={2}
      w="100%"
      cursor="pointer"
      pe={4}
      maxW="300px"
      spacing={0}
      borderRadius="lg"
      align="flex-start"
      justify="flex-start"
      bgGradient={{
        base: 'linear(to-b, brand.400, brand.600)',
        sm: 'linear(to-r, brand.400, brand.600)',
        md: 'linear(to-l, brand.400, brand.600)',
        lg: 'linear(to-l, brand.400, brand.600)',
        xl: 'linear(to-l, brand.400, brand.600)',
      }}
      color="primary.100">
      <HStack spacing={2} justify="flex-start" align="flex-start" w="100%">
        <Icon as={BellIcon} fontSize="2xl" />
        <VStack spacing={0} align="flex-start" justify="flex-start">
          <Text fontWeight="bold" noOfLines={2}>
            {t(title)}
          </Text>
          <Text fontSize="sm" fontWeight="medium" noOfLines={3}>
            {t(body, { name: data.senderName || '' })}
          </Text>
        </VStack>
        <Spacer />
        <Icon cursor="pointer" as={LuX} onClick={() => toast.close(props.id)} />
      </HStack>
    </VStack>
  );
};
export const PushNotificationAlert = () => {
  const dispatch = useDispatch<any>();
  const toast = useToast();
  const { t } = useTranslation();
  const myUser = useMyUser();

  useEffect(() => {
    if (messaging && myUser?.id) {
      const handleNotification = async () => {
        const permission = await Notification.requestPermission();
        console.log(`@@ Permission : ${permission}`);

        if (permission === 'granted') {
          const token = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_PUBLIC_KEY,
          });
          console.log('@@ Token generated : ', token);

          if (token) {
            await dispatch(
              sendPushNotificationToken({
                device: navigator.userAgent,
                token,
                userId: myUser.id,
              }),
            );

            onMessage(messaging, (payload: any) => {
              console.log('@@ payload', payload);
              const { notification, data } = payload;
              const { title, body } = notification;
              if (data?.messageId) {
                toast({
                  title: title,
                  position: 'top-right',
                  variant: 'main',
                  render: (props: RenderProps) => (
                    <NotifyAlertDialog
                      {...props}
                      title={title}
                      body={body}
                      data={data}
                    />
                  ),
                  description: body,
                  status: 'info',
                });
                dispatch(onNewMessageReceived(data.messageId));
              }
              if (data?.notificationId) {
                toast({
                  title: t(title, { name: data.name || '' }),
                  position: 'top-right',
                  variant: 'main',
                  description: t(body, { name: data.name || '' }),
                });
                dispatch(onNewNotificationReceived(data.notificationId));
              }
            });
          }
        }
      };
      handleNotification();
    }
  }, [toast, dispatch, myUser?.id, t]);
  return <></>;
};

export default PushNotificationAlert;
