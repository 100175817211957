import { useToast } from '@chakra-ui/react';
import { setEventsDialog } from 'contexts/redux/dialog/dialogsSlice';
import { createQuote } from 'contexts/redux/quote/quoteSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IEvent, IQoute } from 'services/@types';

export default function useCreateQuote() {
  const { loading } = useSelector((state: RootState) => state.quote);
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const toast = useToast();
  const navigate = useNavigate();
  const [event, setEvent] = useState<IEvent | null>(null);
  const [newQuote, setNewQuote] = useState<IQoute>({
    eventDate: new Date(),
    products: [
      {
        description: '',
        amount: 0,
        price: 0,
        total: 0,
        isVatIncluded: false,
      },
    ],
    customerDetails: [
      {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        idNumber: '',
      },
    ],
    generalPaymentDetails: {
      isVatIncluded: false,
    },
    paymentInInstalments: {
      quantity: 1,
      payment: [
        {
          amount: 0,
          date: new Date(),
        },
      ],
    },
  });

  const currentEventType = useMemo(
    () => newQuote?.eventType || '',
    [newQuote?.eventType],
  );

  const selectedEventType = useMemo(
    () => (currentEventType ? [`event_types.${currentEventType}`] : []),
    [currentEventType],
  );

  const handleChange = (key: string, value: any) => {
    setNewQuote((prev) => ({ ...prev, [key]: value }));
  };

  const handleAddProduct = () => {
    setNewQuote((prev) => ({
      ...prev,
      products: [
        ...(prev?.products || []),
        {
          description: '',
          amount: 0,
          price: 0,
          total: 0,
          isVatIncluded: false,
        },
      ],
    }));
  };

  const handleCreateQuote = useCallback(async () => {
    console.log('handleCreateQuote newQuote: ', newQuote);
    const response = await dispatch(createQuote(newQuote));
    if (response.error) {
      toast({
        title: t('create_quote.error'),
        status: 'error',
      });
      return;
    }

    toast({
      title: t('create_quote.quote_created'),
      status: 'success',
    });
    navigate('/finance/quotes');
  }, [newQuote, dispatch, t, toast, navigate]);

  const handleChangeEventType = useCallback(
    (selected: string) => {
      const formattedSelected = selected.replace('event_types.', '');

      if (selectedEventType.includes(selected)) {
        handleChange('eventType', '');
        return;
      }
      handleChange('eventType', formattedSelected);
    },
    [selectedEventType, handleChange],
  );

  const handleOpenEventDialog = useCallback(() => {
    dispatch(
      setEventsDialog({
        onConfirm: (e: IEvent) => {
          console.log('esetEventsDialog e: ', e);
          setEvent(e);

          setNewQuote((prev) => ({
            ...prev,
            eventId: e?.id,
            location: e?.location?.label,
            eventType: e?.type,
            eventDate: e?.dateAndTime,
            numberOfGuests: e?.numberOfGuests,
          }));
        },
      }),
    );
  }, [dispatch]);

  const handleAddCustomer = () => {
    setNewQuote((prev) => ({
      ...prev,
      customerDetails: [
        ...(prev?.customerDetails || []),
        {
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          idNumber: '',
        },
      ],
    }));
  };

  const handleAddPayment = () => {
    setNewQuote((prev) => ({
      ...prev,
      paymentInInstalments: {
        ...prev.paymentInInstalments,
        payment: [
          ...(prev?.paymentInInstalments?.payment || []),
          {
            amount: 0,
            date: new Date(),
          },
        ],
      },
    }));
  };

  const handleIncreasePaymentQuantity = () => {
    setNewQuote((prev) => ({
      ...prev,
      paymentInInstalments: {
        ...prev.paymentInInstalments,
        quantity: prev.paymentInInstalments.quantity + 1,
      },
    }));
  };

  const handleDecreasePaymentQuantity = () => {
    setNewQuote((prev) => ({
      ...prev,
      paymentInInstalments: {
        ...prev.paymentInInstalments,
        quantity: prev.paymentInInstalments.quantity - 1,
      },
    }));
  };

  return {
    newQuote,
    handleChange,
    handleAddProduct,
    handleChangeEventType,
    selectedEventType,
    handleOpenEventDialog,
    event,
    handleAddCustomer,
    handleAddPayment,
    handleCreateQuote,
    handleIncreasePaymentQuantity,
    handleDecreasePaymentQuantity,
    setNewQuote,
    isLoading: loading,
  };
}
