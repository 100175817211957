import {
  Checkbox,
  Flex,
  Hide,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import DropdownField from 'components/fields/DropdownField';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMoreHorizontal, FiPlus } from 'react-icons/fi';
import { IQoute } from 'services/@types';

const VatCheckbox = memo(
  ({
    product,
    index,
    setNewQuote,
  }: {
    product: any;
    index: number;
    setNewQuote: React.Dispatch<React.SetStateAction<IQoute>>;
  }) => {
    const { t } = useTranslation();
    return (
      <Flex
        alignItems="center"
        gap="4px"
        w={{ base: '100%', md: 'fit-content' }}
        justifyContent={{ base: 'space-between', md: 'flex-start' }}>
        <Checkbox
          variant="roundBrand"
          isChecked={product[index]?.isVatIncluded}
          onChange={(e) => {
            setNewQuote((prev) => ({
              ...prev,
              products: prev.products.map((p, key) =>
                key === index ? { ...p, isVatIncluded: e.target.checked } : p,
              ),
            }));
          }}
          h="50px"
          alignItems="center">
          <Text variant="createQuoteCheckboxText">
            {t('create_quote.vat_17')}
          </Text>
        </Checkbox>
        <IconButton
          aria-label=""
          icon={<Icon as={FiMoreHorizontal} color="#93C3B3" />}
          variant="ghost"
          h="50px"
        />
      </Flex>
    );
  },
);

export default function Products({
  handleAddProduct,
  newQuote,
  handleChange,
  setNewQuote,
}: {
  handleAddProduct: () => void;
  newQuote: IQoute;
  handleChange: (path: string, value: any) => void;
  setNewQuote: React.Dispatch<React.SetStateAction<IQoute>>;
}) {
  const { t } = useTranslation();

  return (
    <VStack alignItems="start" gap="16px">
      <Text variant="createQuoteTitle">{t('create_quote.products')}</Text>
      <Hide below="md">
        <DropdownField
          label={t('create_quote.currency')}
          options={[]}
          placeholder={t('create_quote.currency')}
          menuButton={{
            w: {
              base: '100%',
              md: '239px',
            },
            bg: '#FFFFFF',
          }}
        />
      </Hide>
      {newQuote?.products?.map((product, index) => (
        <Flex wrap="wrap" gap="12px" width="100%" alignItems="end" key={index}>
          <Hide above="md">
            <VatCheckbox
              product={product}
              index={index}
              setNewQuote={setNewQuote}
            />
          </Hide>
          <InputField
            label={t('create_quote.description')}
            placeholder={t('create_quote.description')}
            maxWidth={{ base: '100%', md: '256px' }}
            w={{ base: '100%', md: '256px' }}
            value={product.description}
            onChange={(e) =>
              setNewQuote((prev) => ({
                ...prev,
                products: prev.products.map((p, key) =>
                  key === index ? { ...p, description: e.target.value } : p,
                ),
              }))
            }
          />
          <InputField
            label={t('create_quote.amount')}
            placeholder={t('create_quote.amount')}
            maxWidth={{ base: '100%', md: '115px' }}
            w={{ base: '100%', md: '115px' }}
            type="number"
            value={product.amount}
            onChange={(e) =>
              setNewQuote((prev) => ({
                ...prev,
                products: prev.products.map((p, key) =>
                  key === index
                    ? { ...p, amount: parseInt(e.target.value) }
                    : p,
                ),
              }))
            }
          />
          <InputField
            label={t('create_quote.price')}
            placeholder={t('create_quote.price')}
            maxWidth={{ base: '100%', md: '115px' }}
            w={{ base: '100%', md: '115px' }}
            type="number"
            value={product.price}
            onChange={(e) =>
              setNewQuote((prev) => ({
                ...prev,
                products: prev.products.map((p, key) =>
                  key === index ? { ...p, price: parseInt(e.target.value) } : p,
                ),
              }))
            }
          />
          <InputField
            label={t('create_quote.total_price')}
            placeholder={t('create_quote.total_price')}
            maxWidth={{ base: '100%', md: '125px' }}
            w={{ base: '100%', md: '125px' }}
            bg="#F6F6F6"
            type="number"
            borderColor="#F6F6F6"
            value={product.total}
            onChange={(e) =>
              setNewQuote((prev) => ({
                ...prev,
                products: prev.products.map((p, key) =>
                  key === index ? { ...p, total: parseInt(e.target.value) } : p,
                ),
              }))
            }
          />
          <Hide below="md">
            <VatCheckbox
              product={product}
              index={index}
              setNewQuote={setNewQuote}
            />
          </Hide>
        </Flex>
      ))}
      <HStack>
        <IconButton
          borderRadius="10px"
          icon={<Icon as={FiPlus} color="white" w="30px" h="30px" />}
          onClick={handleAddProduct}
          aria-label="add"
          w="33px"
          h="33px"
          minW="33px"
          bg="#93C3B3"
        />
        <Text>{t('create_quote.add_row')}</Text>
      </HStack>
      <TextField
        label={t('create_quote.comment')}
        placeholder={t('create_quote.comment')}
        w="100%"
        maxHeight={{ base: '233px', md: '203px' }}
        value={newQuote.comment}
        onChange={(e: any) =>
          setNewQuote((prev) => ({ ...prev, comment: e.target.value }))
        }
      />
      <InputField
        label={t(`create_quote.payment_terms`)}
        flex="1"
        w="100%"
        value={newQuote.paymentTerms}
        onChange={(e: any) =>
          setNewQuote((prev) => ({ ...prev, paymentTerms: e.target.value }))
        }
      />
      <InputField
        label={t(`create_quote.legal_text`)}
        w="100%"
        value={newQuote.legalText}
        onChange={(e: any) =>
          setNewQuote((prev) => ({ ...prev, legalText: e.target.value }))
        }
      />
    </VStack>
  );
}
