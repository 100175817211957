import {
  Checkbox,
  Flex,
  HStack,
  Switch,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useMyUserPreferences } from 'contexts/redux/userPreferences/userPreferencesSlice';
import { useTranslation } from 'react-i18next';
import { IUserNotificationMethods, UserPreferences } from 'services/@types';

interface SettingItemProps {
  title: string;
  value: IUserNotificationMethods;
  keyName: string;
  handleUpdateMyUserPreferences: (
    newPreferences: Partial<UserPreferences>,
  ) => void;
}
export default function SettingItem(props: SettingItemProps) {
  const { title, value, handleUpdateMyUserPreferences, keyName } = props;
  const userPreferences = useMyUserPreferences();
  const { i18n, t } = useTranslation();

  return (
    <Flex flexDir="column" alignItems="start" gap="16px" minW="320px">
      <HStack gap="16px">
        <Text variant="settingItemTitle">
          {t(`notification_settings_item.title.${title}`)}
        </Text>
        <Switch
          isChecked={value.enabled}
          dir={i18n.dir()}
          onChange={() =>
            handleUpdateMyUserPreferences({
              notificationPreferences: {
                ...userPreferences.notificationPreferences,
                [keyName]: {
                  ...userPreferences.notificationPreferences[
                    keyName as keyof UserPreferences['notificationPreferences']
                  ],
                  enabled: !value.enabled,
                },
              },
            })
          }
          variant="brand"
        />
      </HStack>
      <VStack alignItems="start" fontWeight="500" gap="16px">
        <Checkbox
          isDisabled={!value.enabled}
          variant="roundBrand"
          isChecked={
            value.methods?.email &&
            value.methods?.message &&
            value.methods?.push
          }
          onChange={(e) => {
            handleUpdateMyUserPreferences({
              notificationPreferences: {
                ...userPreferences.notificationPreferences,
                [keyName]: {
                  ...userPreferences.notificationPreferences[
                    keyName as keyof UserPreferences['notificationPreferences']
                  ],
                  methods: {
                    email: e.target.checked,
                    message: e.target.checked,
                    push: e.target.checked,
                  },
                },
              },
            });
          }}>
          {t('notification_settings_item.all')}
        </Checkbox>
        <Checkbox
          isDisabled={!value.enabled}
          variant="roundBrand"
          isChecked={value.methods?.email}
          onChange={(e) => {
            handleUpdateMyUserPreferences({
              notificationPreferences: {
                ...userPreferences.notificationPreferences,
                [keyName]: {
                  ...userPreferences.notificationPreferences[
                    keyName as keyof UserPreferences['notificationPreferences']
                  ],
                  methods: {
                    ...value.methods,
                    email: e.target.checked,
                  },
                },
              },
            });
          }}>
          {t('notification_settings_item.notify_by_email')}
        </Checkbox>
        <Checkbox
          isDisabled={!value.enabled}
          variant="roundBrand"
          isChecked={value.methods?.message}
          onChange={(e) => {
            handleUpdateMyUserPreferences({
              notificationPreferences: {
                ...userPreferences.notificationPreferences,
                [keyName]: {
                  ...userPreferences.notificationPreferences[
                    keyName as keyof UserPreferences['notificationPreferences']
                  ],
                  methods: {
                    ...value.methods,
                    message: e.target.checked,
                  },
                },
              },
            });
          }}>
          {t('notification_settings_item.notify_by_message')}
        </Checkbox>
        <Checkbox
          isDisabled={!value.enabled}
          variant="roundBrand"
          isChecked={value.methods?.push}
          onChange={(e) => {
            handleUpdateMyUserPreferences({
              notificationPreferences: {
                ...userPreferences.notificationPreferences,
                [keyName]: {
                  ...userPreferences.notificationPreferences[
                    keyName as keyof UserPreferences['notificationPreferences']
                  ],
                  methods: {
                    ...value.methods,
                    push: e.target.checked,
                  },
                },
              },
            });
          }}>
          {t('notification_settings_item.push_notification')}
        </Checkbox>
      </VStack>
    </Flex>
  );
}
