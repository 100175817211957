import { mode } from '@chakra-ui/theme-tools';
export const buttonStyles = {
  components: {
    Button: {
      baseStyle: {
        // fontWeight: '500',
        // // borderRadius: '16px',
        // boxShadow: '45px 76px 113px 7px rgba(112, 144, 176, 0.08)',
        // transition: '.25s all ease',
        // boxSizing: 'border-box',
        // _focus: {
        //   boxShadow: 'none',
        // },
        _active: {
          boxShadow: 'none',
        },
        _disabled: {
          opacity: 1,
          boxShadow: 'none',
        },
        _focus: {
          boxShadow: 'none',
        },
      },
      variants: {
        default: {
          background: '#93C3B3',
          borderRadius: '16px',
          color: '#FFFFFF',
          width: '165.2px',
          height: { base: '50px', md: '44px' },
          fontWeight: '400',
          fontSize: { base: '16px', md: '14px' },
          lineHeight: '32px',
          letterSpacing: '-2%',
          textAlign: 'center',
          transition: 'all 0.5s linear',
          _hover: {
            background: '#93C3B3',
          },
          _active: {
            background: '#465D55',
          },
          _disabled: {
            opacity: 1,
            background: '#00000066',
            _hover: {
              background: '#00000066 !important',
            },
          },
        },
        h1cta: {
          transition: 'all 0.5s linear',
          color: '#FFFFFF',
          fontWeight: '500',
          fontSize: { base: '16px', md: '14px' },
          lineHeight: { base: '16px', md: '14px' },
          textAlign: 'center',
          height: { base: '50px', md: '44px' },
          letterSpacing: '-2%',
          background: '#AA915D',
          borderRadius: '16px',
          _hover: {
            background:
              'linear-gradient(101.56deg, #AA915D 19.94%, #443A25 120.22%)',
          },
          _active: {
            background:
              'linear-gradient(101.56deg, #AA915D 19.94%, #241A15 120.22%)',
          },
          _disabled: {
            opacity: 1,
            background:
              'linear-gradient(101.56deg, rgba(170, 145, 93, 0) 19.94%, rgba(68, 58, 37, 0) 120.22%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
            _hover: {
              background:
                'linear-gradient(101.56deg, rgba(170, 145, 93, 0) 19.94%, rgba(68, 58, 37, 0) 120.22%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)) !important',
            },
          },
        },
        h2outlined: {
          color: '#AA915D',
          fontWeight: '500',
          fontSize: { base: '16px', md: '14px' },
          lineHeight: { base: '16px', md: '14px' },
          textAlign: 'center',
          height: { base: '50px', md: '44px' },
          background: 'transparent',
          border: '1.5px solid #AA915D',
          borderRadius: '16px',
          transition: 'all 0.5s linear',
          _hover: {
            color: '#443A25',
            borderColor: '#443A25',
          },
          _active: {
            color: '#241A15',
            borderColor: '#241A15',
          },
          _disabled: {
            opacity: 1,
            color: 'rgba(0, 0, 0, 0.2)',
            borderColor: 'rgba(0, 0, 0, 0.2)',
            _hover: {
              color: 'rgba(0, 0, 0, 0.2)',
              borderColor: 'rgba(0, 0, 0, 0.2)',
            },
          },
        },
        h3: {
          background: '#465D55',
          borderRadius: '16px',
          color: '#FFFFFF',
          width: '165.2px',
          height: '50px',
          fontWeight: '400',
          fontSize: '24px',
          lineHeight: '32px',
          letterSpacing: '-2%',
          textAlign: 'center',
          transition: 'all 0.5s linear',
          _hover: {
            background: 'rgba(70, 93, 85, 1)',
          },
          _active: {
            background: 'rgba(70, 93, 85, 1)',
          },
          _disabled: {
            opacity: 1,
            background: '#00000066',
            _hover: {
              background: '#00000066 !important',
            },
          },
        },
        h3outlinedRed: {
          width: '138px',
          height: '34px',
          borderRadius: '10px',
          opacity: '0px',
          border: '1px solid rgba(227, 26, 26, 1)',
          color: 'rgba(227, 26, 26, 1)',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '-2%',
          textAlign: 'center',
          _hover: {},
          _active: {},
          _disabled: {
            opacity: 1,
            border: '1px solid rgba(227, 26, 26, 0.5)', // added 50% for testing
            _hover: {
              border: '1px solid rgba(227, 26, 26, 0.5)',
            },
          },
        },
        h3outlinedYellow: {
          height: '34px',
          borderRadius: '10px',
          border: '1px solid rgba(241, 191, 120, 1)',
          color: 'rgba(241, 191, 120, 1)',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '-2%',
          textAlign: 'center',
          _hover: {},
          _active: {},
          _disabled: {
            opacity: 1,
            border: '1px solid rgba(241, 191, 120, 0.5)', // added 50% for testing
            _hover: {
              border: '1px solid rgba(241, 191, 120, 0.5)',
            },
          },
        },
        h3outlinedBlue: {
          height: '34px',
          borderRadius: '10px',
          border: '1px solid #ADD8E6', // addded light blue color
          color: '#ADD8E6',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '-2%',
          textAlign: 'center',
          _hover: {},
          _active: {},
          _disabled: {
            opacity: 0.5,
            border: '1px solid #ADD8E6', // added 50% for testing
            _hover: {
              border: '1px solid #ADD8E6',
            },
          },
        },
        h3outlinedWhiteGreen: {
          height: '34px',
          borderRadius: '10px',
          border: '1px solid #93C3B3', // addded light blue color
          color: '#93C3B3',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '-2%',
          textAlign: 'center',
          _hover: {},
          _active: {},
          _disabled: {
            opacity: 0.5,
            border: '1px solid #93C3B3', // added 50% for testing
            _hover: {
              border: '1px solid #93C3B3',
            },
          },
        },
        h3outlinedGreen: {
          borderRadius: '16px',
          border: '1px solid #AA915D', // addded light blue color
          color: '#AA915D',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '-2%',
          textAlign: 'center',
          height: { base: '50px', md: '44px' },
          _hover: {},
          _active: {},
          _disabled: {
            opacity: 0.5,
            border: '1px solid #AA915D', // added 50% for testing
            _hover: {
              border: '1px solid #AA915D',
            },
          },
        },
        h3outlined: {
          width: '91px',
          height: '34px',
          borderRadius: '10px',
          border: '1px solid #E9EDF7',
          color: '#00000099',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '-2%',
          textAlign: 'center',
          _hover: {
            border: '1px solid #00000099',
          },
          _active: {},
          _disabled: {
            opacity: 0.5,
            border: '1px solid #00000099', // added 50% for testing
            _hover: {
              border: '1px solid #00000099',
            },
          },
        },
        h3white: {
          width: '91px',
          height: '34px',
          borderRadius: '10px',
          bg: 'white',
          border: '1px solid #E9EDF7',
          color: 'brand.900',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '-2%',
          textAlign: 'center',
          _hover: {
            bg: 'gray.200',
          },
          _active: {},
          _disabled: {
            opacity: 0.5,
            bg: 'gray.200',
            _hover: {
              bg: 'gray.200',
            },
          },
        },
        h4: {
          widht: '150px',
          height: '21px',
          bg: 'transparent',
          color: '#AA915D',
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '22px',
          letterSpacing: '-3%',
          textAlign: 'center',
          _hover: {
            background:
              'linear-gradient(101.56deg, #AA915D 19.94%, #443A25 120.22%)', // use gradient from h1cta: _hover
            '-webkit-background-clip': 'text',
            'background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
          },
          _active: {},
          _disabled: {
            opacity: 1,
            color: 'rgba(0, 0, 0, 0.2)',
            _hover: {
              color: 'rgba(0, 0, 0, 0.2)',
            },
          },
        },
        h4underlineRed: {
          widht: '150px',
          height: '21px',
          bg: 'transparent',
          color: '#FF554A',
          textDecoration: 'underline',
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '22px',
          letterSpacing: '-3%',
          textAlign: 'center',
          _hover: {
            textDecoration: 'none',
          },
          _active: {},
          _disabled: {
            opacity: 1,
            color: 'rgba(0, 0, 0, 0.2)',
            _hover: {
              color: 'rgba(0, 0, 0, 0.2)',
            },
          },
        },
        h1BadgeBlue: {
          bg: '#93C3B3',
          color: '#FFFFFF',
          borderRadius: '16px',
          w: '310px',
          h: '50px',
          fontWeight: '500',
          fontSize: '16px',
          lineHeight: '32px',
          letterSpacing: '-2%',
          transition: 'all 0.5s linear',
          _hover: {
            bg: '#769c8f',
          },
          _active: {
            bg: '#465D55',
          },
          _disabled: {
            opacity: 0.4,
            bg: '#93C3B3',
            _hover: {
              bg: '#93C3B3 !important',
            },
          },
        },
        google: () => ({
          transition: 'all 0.3s linear',
          borderRadius: '16px',
          height: { base: '50px', md: '44px' },
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '20px',
          letterSpacing: '-2%',
          color: '#2B3674',
          bg: '#FFFFFF',
          _focus: {},
          _active: {},
          _hover: {},
        }),
        outlineTag: {
          bg: 'transparent',
          fontWeight: '700',
          fontSize: '8.99px',
          lineHeight: '16.47px',
          letterSpacing: '-2%',
          color: '#00000066',
          border: '0.9px solid',
          borderColor: '#0000004D',
          borderRadius: '8.99px',
          overflow: 'hidden',
          p: '2.06px 9.61px',
          height: 'fit-content',
        },
        accept: {
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '24px',
          letterSpacing: '-2%',
          height: '30px',
          bg: '#05CD991A',
          borderRadius: '10px',
          color: '#01B574',
          _hover: {
            bg: 'green.400',
            color: 'white',
          },
        },
        decline: {
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '24px',
          letterSpacing: '-2%',
          height: '30px',
          bg: '#EE5D501A',
          color: 'horizonRed.500',
          borderRadius: '10px',
          _hover: {
            bg: 'red.400',
            color: 'white',
          },
        },
        underlineRed: (props: any) => ({
          bg: 'transparent',
          overflow: 'hidden',
          textDecoration: 'underline',
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '-2%',
          fontWeight: '400',
          transition: 'all .25s ease',
          color: '#FF4D4F',
          _hover: {},
        }),
        badgeTagBlue: {
          bg: '#93C3B3',
          borderRadius: '10px',
          h: '50px',
          color: '#00000099',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '-2%',
        },
        // using for input date field
        date: () => ({
          bg: mode('transparent', 'navy.800'),
          border: '1px solid',
          color: 'rgba(0, 0, 0, 0.8)',
          borderColor: 'general.100',
          borderRadius: '16px',
          fontSize: '14px',
          fontWeight: '500',
          maxH: '50px',
          p: '24px',
        }),
        assignSelectedOptions: () => ({
          h: '26px',
          color: '#000000CC',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '20px',
          letterSpacing: '-2%',
          bg: '#0000000D',
          borderRadius: '10px',
          cursor: 'pointer',
          maxWidth: '200px',
          wordBreak: 'break-all',
          noOfLines: 1,
        }),
        time: () => ({
          bg: mode('transparent', 'navy.800'),
          border: '1px solid',
          color: 'rgba(0, 0, 0, 0.8)',
          borderColor: 'general.100',
          borderRadius: '16px',
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '15px',
          letterSpacing: '2px',
          maxH: '50px',
          p: '24px',
        }),
      },
    },
  },
};
