import {
  Button,
  CircularProgress,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import AuthImage from 'components/authImage/AuthImage';
import { closeViewPhotoDialog } from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MediaItem } from 'services/@types';

export const ViewPhotoModal: React.FC = () => {
  console.log('view photo modal');
  const { onClose, isOpen, onOpen } = useDisclosure();
  const { t } = useTranslation();
  const dialog = useSelector(
    (state: RootState) => state.dialogs.viewPhotoDialog,
  );
  const dispatch = useDispatch<any>();
  const mediaItem = dialog?.item as MediaItem;
  const [isReady, setIsReady] = React.useState(false);
  const [resolvedLink, setResolvedLink] = React.useState<string | null>(null);

  const handleCancelClicked = useCallback(() => {
    dispatch(closeViewPhotoDialog());
  }, [dispatch]);
  useEffect(() => {
    if (mediaItem?.type === 'video' && mediaItem?.url) {
      if (mediaItem.url.includes('watch?v=')) {
        const videoId = mediaItem.url.split('watch?v=')[1];
        // parse url and take only v= part:
        const queryParams = videoId.split('&');
        setResolvedLink(`https://www.youtube.com/embed/${queryParams[0]}`);
      } else if (mediaItem.url.includes('youtu.be')) {
        const videoId = mediaItem.url.split('youtu.be/')[1];
        setResolvedLink(`https://www.youtube.com/embed/${videoId}`);
      } else if (mediaItem.url.includes('vimeo')) {
        const videoId = mediaItem.url.split('vimeo.com/')[1];
        setResolvedLink(`https://player.vimeo.com/video/${videoId}`);
      } else {
        setResolvedLink(mediaItem.url);
      }
      setIsReady(true);
    }
  }, [mediaItem]);
  useEffect(() => {
    if (dialog?.item) {
      onOpen();
    } else {
      onClose();
    }
  }, [dialog?.item, onClose, onOpen]);
  const handleCloseCompleted = useCallback(() => {
    dispatch(closeViewPhotoDialog());
    setIsReady(false);
  }, [dispatch]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick
      onCloseComplete={handleCloseCompleted}
      isCentered>
      <ModalOverlay bg="#000000dc" />
      <ModalContent
        borderRadius={'3xl'}
        mx={2}
        h={'90vh'}
        bg="transparent"
        boxShadow={'none'}
        display={!isReady ? 'block' : 'none'}>
        <Flex
          w="100%"
          justify={'center'}
          h={'100%'}
          align={'center'}
          direction="column">
          <CircularProgress
            isIndeterminate
            color={'brand.600'}
            trackColor="white"
          />
          <Text color={'white'} fontWeight="bold">
            {t('gallery.dialogs.loading')}
          </Text>
        </Flex>
      </ModalContent>
      <ModalContent
        p={4}
        borderRadius="3xl"
        mx={1}
        maxW="4xl"
        display={isReady ? 'block' : 'none'}>
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        <VStack>
          {mediaItem?.type === 'image' && (
            <AuthImage
              w={'100%'}
              h={'100%'}
              maxW={'70%'}
              onReady={() => setIsReady(true)}
              size="lg"
              // borderRadius="3xl"
              path={
                mediaItem?.media?.length
                  ? mediaItem?.media[0].url
                  : mediaItem?.url
              }
            />
          )}
          {mediaItem?.type === 'video' && (
            <iframe
              width="100%"
              height="100%"
              style={{ minHeight: '500px' }}
              src={resolvedLink || mediaItem?.url}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></iframe>
          )}
          <Button
            variant={'outlineBlue'}
            w={'30%'}
            display={isReady ? 'block' : 'none'}
            onClick={handleCancelClicked}>
            {t('gallery.dialogs.close')}
          </Button>
        </VStack>
      </ModalContent>
    </Modal>
  );
};
