import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import NoEventsImg from 'assets/img/eventsOverview/noEventsCreated.png';
import { useNavigate } from 'react-router-dom';

export default function NoEventsCreated() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      h="100%"
      flexDirection="column"
      gap="36px">
      <Image w="320px" h="280px" src={NoEventsImg} alt="" />
      <Box gap="16px" textAlign="center">
        <Text variant="noEventsTitle">
          {t('events_overview.no_events_created_yet')}
        </Text>
        <Text variant="noEventsSubTitle">
          {t('events_overview.no_events_created_yet_description')}
        </Text>
      </Box>
      <Button
        w="355px"
        variant="h1cta"
        onClick={() => navigate('/main/managment/events/create')}>
        {t('events_overview.create_event')}
      </Button>
    </Flex>
  );
}
