/* eslint-disable */

import { NavLink, useLocation } from 'react-router-dom';
// chakra imports
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Icon,
  List,
  ListItem,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
// Assets
import { useTranslation } from 'react-i18next';
import { TiChevronLeft, TiChevronRight } from 'react-icons/ti';
import SVGColorModifier from 'components/imageColorModifier';
import ImageColorModifier from 'components/imageColorModifier';

export function SidebarLinks(props: {
  routes: RoutesType[];
  [x: string]: any;
}) {
  const { t, i18n } = useTranslation();
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue('#465D55', 'white');
  let inactiveColor = 'brand.400';
  let activeIcon = useColorModeValue('brand.500', 'white');

  const { routes, hovered, mini } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes: RoutesType[]) => {
    return routes
      .filter((el) => el.isSidebarVisible)
      .map((route, key) => {
        if (route.collapse) {
          return (
            <Accordion
              defaultIndex={activeRoute(route.path.toLowerCase()) ? 0 : 1}
              allowToggle
              w="100%"
              key={key}>
              <AccordionItem maxW="100%" w="100%" border="none" key={key}>
                <AccordionButton
                  display="flex"
                  ps={0}
                  alignItems="center"
                  justifyContent="center"
                  _hover={{
                    bg: 'unset',
                  }}
                  _focus={{
                    boxShadow: 'none',
                  }}
                  borderRadius="8px"
                  w={{
                    sm: '100%',
                    xl: '100%',
                    '2xl': '95%',
                  }}
                  px={route.icon ? null : '0px'}
                  py="0px"
                  bg={'transparent'}
                  ms={0}>
                  {route.icon ? (
                    <Flex align="center" justify={'start'} w="100%">
                      <HStack
                        // mb="40px"
                        spacing={
                          activeRoute(route.path.toLowerCase())
                            ? '22px'
                            : '26px'
                        }>
                        <Flex
                          w="100%"
                          gap={4}
                          alignItems="center"
                          justifyContent="start">
                          <ImageColorModifier
                            icon={route.icon}
                            isActive={activeRoute(route.path.toLowerCase())}
                            activeColor={activeColor}
                            inactiveColor={inactiveColor}
                            w={5}
                          />
                          <Text
                            display={
                              mini === false
                                ? 'block'
                                : mini === true && hovered === true
                                ? 'block'
                                : 'none'
                            }
                            me="auto"
                            color={
                              activeRoute(route.path.toLowerCase())
                                ? activeColor
                                : inactiveColor
                            }
                            fontWeight="700"
                            fontSize="md">
                            {t(route.name)}
                          </Text>
                        </Flex>
                      </HStack>
                      <AccordionIcon
                        display={
                          mini === false
                            ? 'block'
                            : mini === true && hovered === true
                            ? 'block'
                            : 'none'
                        }
                        fontSize="32px"
                        ms="auto"
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? 'brand.300'
                            : 'brand.400'
                        }
                        transform={route.icon ? null : 'translateX(-70%)'}
                      />
                    </Flex>
                  ) : (
                    <Flex
                      pt="0px"
                      pb="0px"
                      ps={6}
                      mt={6}
                      justify={'center'}
                      alignItems="center"
                      w="100%">
                      <HStack
                        spacing={
                          activeRoute(route.path.toLowerCase())
                            ? '22px'
                            : '26px'
                        }
                        ps={
                          mini === false
                            ? '0px'
                            : mini === true && hovered === true
                            ? '55px'
                            : '0px'
                        }>
                        <Text
                          me="auto"
                          color={
                            activeRoute(route.path.toLowerCase())
                              ? activeColor
                              : inactiveColor
                          }
                          fontWeight="400"
                          fontSize="sm">
                          {t(
                            mini === false
                              ? route.name
                              : mini === true && hovered === true
                              ? route.name
                              : route.name[0],
                          )}
                        </Text>
                      </HStack>
                      <AccordionIcon
                        display={
                          mini === false
                            ? 'block'
                            : mini === true && hovered === true
                            ? 'block'
                            : 'none'
                        }
                        ms="auto"
                        color={'secondaryGray.600'}
                        transform={null}
                      />
                    </Flex>
                  )}
                </AccordionButton>
                <AccordionPanel
                  display={
                    mini === false
                      ? 'block'
                      : mini === true && hovered === true
                      ? 'block'
                      : 'flex'
                  }
                  justifyContent="center"
                  alignItems="center"
                  flexDirection={'column'}
                  // bg="blue"
                  pe={route.icon ? '14px !important' : '0px'}
                  py="0px"
                  mt={4}
                  ps={route.icon ? '14px !important' : '8px'}>
                  <List>
                    {
                      route.icon
                        ? createLinks(route.items) // for bullet accordion links
                        : createAccordionLinks(route.items) // for non-bullet accordion links
                    }
                  </List>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          );
        } else {
          return (
            <NavLink to={route.layout + route.path} key={key}>
              {route.icon ? (
                <Flex
                  align="center"
                  justifyContent="space-between"
                  w="100%"
                  mb="0px">
                  <HStack
                    mb="6px"
                    spacing={
                      activeRoute(route.path.toLowerCase()) ? '22px' : '26px'
                    }>
                    <Flex
                      w="100%"
                      gap={4}
                      alignItems="center"
                      justifyContent="center">
                      <ImageColorModifier
                        icon={route.icon}
                        isActive={activeRoute(route.path.toLowerCase())}
                        activeColor={activeColor}
                        inactiveColor={inactiveColor}
                        w={5}
                      />
                      <Text
                        me="auto"
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? activeColor
                            : inactiveColor
                        }
                        fontWeight="700">
                        {t(
                          mini === false
                            ? route.name
                            : mini === true && hovered === true
                            ? route.name
                            : route.name[0],
                        )}
                      </Text>
                    </Flex>
                  </HStack>
                </Flex>
              ) : (
                <ListItem ms={null}>
                  <Flex
                    gap={2}
                    ps={'0px'}
                    // _hover={{ ps: '30px' }}
                    role="group"
                    alignItems="center"
                    mt="23px">
                    <Icon
                      //   display={
                      // activeRoute(route.path.toLowerCase()) ? 'block' : 'none'
                      //   }
                      _groupHover={{
                        opacity: 1,
                        transform: `translateX(${
                          i18n.dir() === 'rtl' ? '-5px' : '5px'
                        })`,
                      }}
                      opacity={activeRoute(route.path.toLowerCase()) ? 1 : 0}
                      transition="all 0.2s ease-in-out"
                      //   as={TiChevronLeft}
                      as={i18n.dir() === 'rtl' ? TiChevronLeft : TiChevronRight}
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : inactiveColor
                      }
                    />
                    <Text
                      fontSize={'sm'}
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : inactiveColor
                      }
                      // letterSpacing="1px"
                      // fontSize="sm"
                    >
                      {t(
                        mini === false
                          ? route.name
                          : mini === true && hovered === true
                          ? route.name
                          : route.name[0],
                      )}
                    </Text>
                  </Flex>
                </ListItem>
              )}
            </NavLink>
          );
        }
      });
  };
  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createAccordionLinks = (routes: RoutesType[]) => {
    return routes.map((route: RoutesType, key: number) => {
      return (
        <NavLink to={route.layout + route.path} key={key}>
          <ListItem
            display="flex"
            alignItems="center"
            key={key}
            py={2}
            ps={8}
            // mt={key === 0 ? '13px' : '23px'}
          >
            {/* <Icon w="6px" h="6px" me="8px" as={FaCircle} color={activeIcon} /> */}
            <Text
              // display={
              //   mini === false
              //     ? 'block'
              //     : mini === true && hovered === true
              //     ? 'block'
              //     : 'none'
              // }

              color={
                activeRoute(route.path.toLowerCase())
                  ? activeColor
                  : inactiveColor
              }
              fontWeight={
                activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'
              }
              fontSize="sm">
              {t(
                mini === false
                  ? route.name
                  : mini === true && hovered === true
                  ? route.name
                  : route.name[0],
              )}
            </Text>
          </ListItem>
        </NavLink>
      );
    });
  };
  //  BRAND
  return (
    <VStack gap={'36px'} w="100%" align={'start'}>
      {createLinks(routes)}
    </VStack>
  );
}

export default SidebarLinks;
