/*eslint-disable*/

import {
  Flex,
  Link,
  List,
  ListItem,
  useColorModeValue,
} from '@chakra-ui/react';
import { FbIcon, InstIcon } from 'components/icons/FooterAuthIcons';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  let linkColor = useColorModeValue({ base: 'gray.400', lg: 'white' }, 'white');
  const { t } = useTranslation();
  return (
    <Flex
      zIndex={{ base: '0', lg: '3' }}
      flexDirection={{
        base: 'column',
        lg: 'row',
      }}
      alignItems={{
        base: 'center',
        xl: 'start',
      }}
      position="absolute"
      w="100%"
      bottom="0px"
      justifyContent="flex-end"
      px={{ base: '30px', md: '0px' }}
      pb={{ base: undefined, lg: '30px' }}>
      <List display="flex">
        <ListItem
          me={{
            base: '20px',
            md: '58px',
          }}>
          <Link
            fontWeight="500"
            color={linkColor}
            href="https://simmmple.com/terms-of-service">
            {t('footer.terms_of_service')}
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: '20px',
            md: '58px',
          }}>
          <Link
            fontWeight="500"
            color={linkColor}
            href="mailto:hello@simmmple.com">
            {t('footer.support')}
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: '20px',
            md: '25px',
          }}>
          <FbIcon fill={linkColor} stroke={linkColor} />
        </ListItem>
        <ListItem
          me={{
            base: '20px',
            md: '99px',
          }}>
          <InstIcon fill={linkColor} stroke={linkColor} />
        </ListItem>
      </List>
    </Flex>
  );
}
