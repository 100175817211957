// src/features/calendarEvents/userSlice.ts
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import moment from 'moment';
import { CalendarEvent, IEvent, IIntegration, IMeeting } from 'services/@types';
import CalendarEventService from 'services/calendarEvent.api';
import {
  closeAddSeatDialog,
  closeAlertDialog,
  closeSplashDialog,
  openAlertDialog,
  setDeletedExternalEventsDialog,
  setDeletedExternalMeetingsDialog,
} from '../dialog/dialogsSlice';
import { updateEvent } from '../event/eventSlice';
import { updateMeeting } from '../meeting/meetingSlice';
import store from '../store';
export interface CalendarEventState {
  calendarEvents: CalendarEvent[];
  fromDate: Date;
  toDate: Date;
  integrations: IIntegration[];
  deletedExternalCalendarEvents: IEvent[];
  deletedExternalCalendarMeetings: IMeeting[];
  loading: boolean;
  loadingId: string | null;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: CalendarEventState = {
  deletedExternalCalendarEvents: [],
  deletedExternalCalendarMeetings: [],
  calendarEvents: [],
  integrations: [],
  fromDate: new Date(),
  toDate: moment().add(1, 'month').toDate(),
  loadingId: null,
  loading: false,
  error: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};

export const fetchCalendarEvent = createAsyncThunk(
  'calendarEvents/fetchCalendarEvent',
  async (tagId: string, { rejectWithValue }) => {
    try {
      const response = await CalendarEventService.getCalendarEvent(tagId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createCalendarEvent = createAsyncThunk(
  'calendarEvents/createCalendarEvent',
  async (calendarEvent: CalendarEvent, { rejectWithValue }) => {
    try {
      const newCalendarEvent = await CalendarEventService.createCalendarEvents(
        calendarEvent,
      );
      console.log('#Redux create business ->', newCalendarEvent);
      store.dispatch(closeSplashDialog());
      store.dispatch(closeAddSeatDialog());
      return newCalendarEvent;
    } catch (error) {
      const e = error as AxiosError;
      if (e.response.status === 409) {
        store.dispatch(
          openAlertDialog({
            title: 'calendarEvent_supplier.already_calendarEventd',
            content:
              'calendarEvent_supplier.already_calendarEventd_description',
          }),
        );
      }

      store.dispatch(closeSplashDialog());
      return rejectWithValue(error);
    }
  },
);

export const updateCalendarEvent = createAsyncThunk(
  'calendarEvents/updateCalendarEvent',
  async (
    { userId, updates }: { userId: string; updates: Partial<CalendarEvent> },
    { rejectWithValue },
  ) => {
    try {
      const response = await CalendarEventService.updateCalendarEvent(
        userId,
        updates,
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteCalendarEvent = createAsyncThunk(
  'calendarEvents/deleteCalendarEvent',
  async (tagid: string, { rejectWithValue }) => {
    try {
      await CalendarEventService.deleteCalendarEvent(tagid);
      return tagid;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateViewedCalendars = createAsyncThunk(
  'calendarEvents/updateViewedCalendars',
  async (
    {
      calendars,
      currentDate,
      calendarViewType,
    }: {
      calendars?: string[];
      currentDate?: Date;
      calendarViewType?: 'month' | 'week' | 'day';
    },
    { rejectWithValue, extra },
  ) => {
    try {
      let fromDate: string;
      let toDate: string;

      // Calculate date ranges based on view type
      switch (calendarViewType) {
        case 'month':
          fromDate = moment(currentDate).startOf('month').toISOString();
          toDate = moment(currentDate).endOf('month').toISOString();
          break;
        case 'week':
          fromDate = moment(currentDate).startOf('week').toISOString();
          toDate = moment(currentDate).endOf('week').toISOString();
          break;
        case 'day':
          fromDate = moment(currentDate).startOf('day').toISOString();
          toDate = moment(currentDate).endOf('day').toISOString();
          break;
        default:
          // Default to current state dates if no view type specified
          fromDate = store.getState().calendarEvent.fromDate.toISOString();
          toDate = store.getState().calendarEvent.toDate.toISOString();
      }

      const filter = { fromDate, toDate };

      const eventsResponses = await Promise.all(
        calendars?.map((i) =>
          CalendarEventService.getCalendarEvents(i, filter),
        ),
      );

      const response = {
        events: [...eventsResponses.map((r) => r.data)],
        deletedExternalCalendarEvents: [
          ...eventsResponses
            .filter((r) => r.deletedExternalCalendarEvents)
            .map((r) => r.deletedExternalCalendarEvents),
        ],
        deletedExternalCalendarMeetings: [
          ...eventsResponses
            .filter((r) => r.deletedExternalCalendarMeetings)
            .map((r) => r.deletedExternalCalendarMeetings),
        ],
      };

      // open alert dialog if there are deleted external calendar events
      if (response.deletedExternalCalendarEvents.flat().length > 0) {
        store.dispatch(
          openAlertDialog({
            title: 'deleted_external_events_found',
            content: 'deleted_external_events_found_description',
            onConfirm: (index: number) => {
              store.dispatch(closeAlertDialog({ index }));
              store.dispatch(setDeletedExternalEventsDialog({}));
            },
            onClose: (index: number) => {
              store.dispatch(closeAlertDialog({ index }));
              response.deletedExternalCalendarEvents.flat().map(
                async (event) =>
                  await store.dispatch(
                    updateEvent({
                      id: event.id,
                      updates: {
                        calendarId: null,
                        externalCalendarEventId: null,
                        meetLink: null,
                      },
                    }),
                  ),
              );
            },
          }),
        );
      }
      if (response.deletedExternalCalendarMeetings.flat().length > 0) {
        store.dispatch(
          openAlertDialog({
            title: 'deleted_external_meetings_found',
            content: 'deleted_external_meetings_found_description',
            onConfirm: (index: number) => {
              store.dispatch(setDeletedExternalMeetingsDialog({}));
              store.dispatch(closeAlertDialog({ index }));
            },
            onClose: (index: number) => {
              store.dispatch(closeAlertDialog({ index }));
              response.deletedExternalCalendarMeetings.flat().map(
                async (meeting) =>
                  await store.dispatch(
                    updateMeeting({
                      id: meeting.id,
                      updates: {
                        calendarId: null,
                        externalCalendarEventId: null,
                        meetLink: null,
                      },
                    }),
                  ),
              );
            },
          }),
        );
      }
      console.log('##Redux updateViewedCalendars ->', response);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const userSlice = createSlice({
  name: 'businesses',
  initialState,
  reducers: {
    // Any synchronous actions can be defined here
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCalendarEvent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCalendarEvent.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.calendarEvents = [action.payload];
      })
      .addCase(fetchCalendarEvent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(createCalendarEvent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCalendarEvent.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.calendarEvents.push(action.payload);
      })
      .addCase(createCalendarEvent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateCalendarEvent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCalendarEvent.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.calendarEvents = state.calendarEvents.map((calendarEvent) =>
          calendarEvent.id === action.payload.id
            ? action.payload
            : calendarEvent,
        );
      })
      .addCase(updateCalendarEvent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(deleteCalendarEvent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCalendarEvent.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.calendarEvents = state.calendarEvents.filter(
          (calendarEvent) => calendarEvent.id !== action.payload,
        );
      })
      .addCase(deleteCalendarEvent.rejected, (state, action) => {
        console.log('##Redux deleteCalendarEvent -> rejected');
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateViewedCalendars.pending, (state) => {
        console.log('##Redux updateViewedCalendars -> pending');
        state.loading = true;
        state.error = null;
      })
      .addCase(updateViewedCalendars.fulfilled, (state, action) => {
        console.log(`##Redux updateViewedCalendars ->`, action.payload);
        state.loading = false;
        state.error = null;
        state.calendarEvents = [
          ...action.payload.events.flat().map((i) => ({
            ...i,
            start: new Date(i.start),
            end: new Date(i.end),
          })),
        ];
        state.deletedExternalCalendarEvents = [
          ...action.payload.deletedExternalCalendarEvents.flat(),
        ];
        state.deletedExternalCalendarMeetings = [
          ...action.payload.deletedExternalCalendarMeetings.flat(),
        ];
      })
      .addCase(updateViewedCalendars.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default userSlice.reducer;
