import { Flex, Icon, IconButton, Text, useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import AuthImage from 'components/authImage/AuthImage';
import { setViewPhotoDialog } from 'contexts/redux/dialog/dialogsSlice';
import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegTrashAlt } from 'react-icons/fa';
import { FiMoreHorizontal } from 'react-icons/fi';
import { MdEdit } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { INote, User } from 'services/@types';
import _uploadService from 'services/upload.api';
import UserService from 'services/user.api';
import { isImageName } from 'utils/isImageName';

export default function TrackItemNote({
  note,
  onDeleteNote,
  onEditNote,
  suggestedUsers,
}: {
  note: INote;
  onDeleteNote: () => void;
  onEditNote: () => void;
  suggestedUsers: User[];
}) {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const { t, i18n } = useTranslation();
  const [isBtnVisible, setIsBtnVisible] = useState<boolean>(false);
  const dispatch = useDispatch();
  const senderInfoResult = useQuery({
    queryKey: [`senderInfo-${note.creator}`],
    queryFn: () => UserService.getUser(note.creator),
    enabled: !!note.creator,
  });

  const onDelete = useCallback(() => {
    onDeleteNote();
    onClose();
  }, [onDeleteNote, onClose]);

  const onEdit = useCallback(() => {
    onEditNote();
    onClose();
  }, [onEditNote, onClose]);

  const handleDownloadFile = useCallback(
    async (item: { url: string; type: string; displayName: string }) => {
      try {
        console.log('download file');

        const response = await _uploadService.getUpload(item.url, 'original');
        console.log('response', response);
        const imageData = URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = imageData;
        a.download = item.displayName;
        a.click();
      } catch (error) {
        console.error(error);
      }
    },
    [],
  );

  const handleItemAttachmentClicked = useCallback(
    (item) => {
      if (isImageName(item?.displayName)) {
        dispatch(setViewPhotoDialog({ item: { ...item, type: 'image' } }));
      } else {
        handleDownloadFile(item);
      }
    },
    [dispatch, handleDownloadFile],
  );
  return (
    <Flex
      gap="12px"
      position="relative"
      onMouseEnter={() => setIsBtnVisible(true)}
      onMouseLeave={() => {
        setIsBtnVisible(false);
        onClose();
      }}>
      <AuthImage
        path={
          senderInfoResult.data?.media?.find(
            (media) => media?.type === 'avatar',
          )?.url || ''
        }
        name={senderInfoResult.data?.firstName}
        isAvatar
        w="24px"
        h="24px"
        borderRadius="50%"
        minW="24px"
        opacity={isOpen ? 0.3 : 1}
      />
      <Flex
        flexDirection="column"
        w="100%"
        gap="0px"
        opacity={isOpen ? 0.3 : 1}>
        <Flex gap="2px">
          <Text variant="trackItemNoteTitle" mb={2}>
            {senderInfoResult.data?.firstName} {senderInfoResult.data?.lastName}
          </Text>
          <Text variant="trackItemNoteTitleDate">
            {note?.createdAt && format(new Date(note.createdAt), 'dd MMM yyyy')}
          </Text>
        </Flex>

        <Text display="inline" opacity={0.4} lineHeight="14px" fontSize={'sm'}>
          {note.text}
        </Text>
        {note.media?.length > 0 && (
          <Text
            variant="trackItemNoteTitle"
            color="brand.900"
            fontSize={'sm'}
            mt={2}
            cursor={'pointer'}
            onClick={() => handleItemAttachmentClicked(note.media[0])}
            textDecorationLine={'underline'}>
            {note.media?.length} {t('tracked_event.attachments')}
          </Text>
        )}
      </Flex>

      <Flex position="relative">
        <IconButton
          variant="actionIconTransparent"
          icon={<Icon as={FiMoreHorizontal} w="20px" h="20px" />}
          onClick={onToggle}
          aria-label="more options"
          transition="all 0.4s linear"
          visibility={{
            base: 'visible',
            md: isBtnVisible ? 'visible' : 'hidden',
          }}
        />

        <Flex
          position="absolute"
          left={i18n.dir() === 'rtl' ? '0' : undefined}
          right={i18n.dir() === 'ltr' ? '0' : undefined}
          // right={i18n.dir() === 'rtl' ? '0' : '100%'}
          // left={i18n.dir() === 'ltr' ? '0' : '100%'}
          pointerEvents={isOpen ? 'auto' : 'none'}
          top="0"
          borderRadius="md"
          p="1"
          transition="all 0.2s linear"
          gap="1"
          opacity={isOpen ? 1 : 0}
          zIndex={1}>
          <IconButton
            variant="actionIconRed"
            onClick={onDelete}
            icon={<Icon as={FaRegTrashAlt} w="20px" h="20px" />}
            aria-label="delete"
          />
          <IconButton
            variant="actionIconGrey"
            onClick={onEdit}
            icon={<Icon as={MdEdit} w="20px" h="20px" />}
            aria-label="edit"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
