import { IconButton, Image } from '@chakra-ui/react';
import RefreshIcon from 'assets/svg/refreshFiltersButton/icon.svg';
import { useCallback, useRef } from 'react';

export default function RefreshFiltersButton({
  onClick,
}: {
  onClick: () => void;
}) {
  const btnRef = useRef<HTMLButtonElement | null>(null);

  const handleRotate = (rotate: number) => {
    if (btnRef.current) {
      const children = btnRef.current.children;
      for (let i = 0; i < children.length; i++) {
        const child = children[i] as HTMLElement;
        child.style.transition = 'all 0.3s';
        child.style.transform = `rotate(${rotate}deg)`;
      }
    }
  };
  const handleClick = useCallback(() => {
    handleRotate(-360);
    onClick();
  }, [onClick]);
  return (
    <IconButton
      ref={btnRef}
      onClick={handleClick}
      onMouseEnter={() => handleRotate(-180)}
      onMouseLeave={() => handleRotate(0)}
      aria-label="refresh filters"
      icon={<Image src={RefreshIcon} alt="" w="19px" h="19px" />}
      width={{ base: '100%', md: '50px' }}
      minWidth={{ base: '100%', md: '50px' }}
      h="50px"
      borderRadius="16px"
      border="1px solid #93C3B3"
    />
  );
}
