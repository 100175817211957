import {
  Flex,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import NotificationComponent from 'components/notifications/NotificationComponent';
import { syncLocalUnreadNotifications } from 'contexts/redux/notifications/notificationsSlice';
import { RootState } from 'contexts/redux/store';
import useGetUnreadNotificationsCount from 'queries/notifications';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LuX } from 'react-icons/lu';
import { MdNotificationsNone } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import NotificationsEmptySvg from '../../assets/svg/notification/notifications-empty.svg';
import ScaleIcon from '../../assets/svg/notification/scale.svg';

interface NotificationsMenuProps {
  isOpen: boolean;
  onClose: () => void;
  onToggle: () => void;
}

const NotificationsMenu: React.FC<NotificationsMenuProps> = ({
  isOpen,
  onClose,
  onToggle,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { notifications, unreadNotifications, notificationReceived } =
    useSelector((state: RootState) => ({
      notifications: state.notifications.notifications,
      unreadNotifications: state.notifications.unreadNotifications,
      notificationReceived: state.notifications.notificationReceived,
    }));

  const unreadNotificationsCount = useGetUnreadNotificationsCount(
    notificationReceived?.id,
  );

  const onHandleOpenNotificationMenu = useCallback(() => {
    dispatch(syncLocalUnreadNotifications());
  }, [dispatch]);

  const onHandleCloseNotificationMenu = useCallback(() => {
    unreadNotificationsCount.refetch();
  }, [unreadNotificationsCount]);

  // Chakra Color Mode
  const menuBg = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Menu isOpen={isOpen} onClose={onClose}>
      <MenuButton p="0px" position="relative" onClick={onToggle}>
        <Flex
          alignItems="center"
          justifyContent="center"
          position="absolute"
          display={unreadNotificationsCount.data?.count > 0 ? 'flex' : 'none'}
          w="12px"
          h="12px"
          top="4px"
          right="6px"
          borderRadius="50%"
          bg="#AA915D">
          <Text variant="missedMessageCounter">
            {unreadNotificationsCount.data?.count || 10}
          </Text>
        </Flex>
        <Icon
          mt="6px"
          as={MdNotificationsNone}
          color={'brand.400'}
          w="24px"
          p={'1px'}
          h="24px"
          me="10px"
        />
      </MenuButton>
      <MenuList
        boxShadow={shadow}
        p="20px"
        borderRadius="20px"
        bg={menuBg}
        border="none"
        mt="22px"
        me={{ base: '30px', md: 'unset' }}
        height="100%"
        maxH="85vh"
        overflowY="auto"
        w="328px">
        <Flex
          w="100%"
          mb="20px"
          alignItems="center"
          justifyContent="space-between">
          <Text variant="cardTitle">{t('notifications.title')}</Text>
          <Flex alignItems="center" gap="34px">
            <Image src={ScaleIcon} alt="" w="18px" h="18px" cursor="pointer" />
            <IconButton
              variant="actionIconTransparent"
              aria-label="close-notifications"
              icon={<LuX fontSize="24px" />}
              onClick={onClose}
              color="#8E7A70"
              w="18px"
              h="18px"
              cursor="pointer"
            />
          </Flex>
        </Flex>
        <Flex flexDirection="column" py={1}>
          {unreadNotifications.length > 0 && (
            <>
              <Text fontSize="xl" fontWeight={'bold'} my={5}>
                {t('notifications.new')}
              </Text>
              <Flex flexDirection="column" gap={4}>
                {unreadNotifications.map((notification) => (
                  <Flex
                    key={notification.id}
                    bg="#93C3B31A"
                    borderRadius="12px">
                    <NotificationComponent notification={notification} />
                  </Flex>
                ))}
              </Flex>
            </>
          )}
          {notifications.length > 0 && (
            <>
              <Text fontSize="xl" fontWeight={'bold'} my={5}>
                {t('notifications.read')}
              </Text>
              <Flex flexDirection="column" gap={4}>
                {notifications.map((notification) => (
                  <NotificationComponent
                    key={notification.id}
                    notification={notification}
                  />
                ))}
              </Flex>
            </>
          )}
          {notifications.length === 0 && unreadNotifications.length === 0 && (
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              pt={8}
              gap={4}>
              <Image src={NotificationsEmptySvg} alt="" w="140px" h="128px" />
              <Text textAlign="center" color="gray.500" fontSize="sm">
                {t('notifications.no_notifications')}
              </Text>
            </Flex>
          )}
        </Flex>
      </MenuList>
    </Menu>
  );
};

export default NotificationsMenu;
