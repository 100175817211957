import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import pushNotificationTokenService, {
  PushNotificationTokenOmitted,
} from 'services/pushNotificationToken.api';
export interface PushNotificationTokenState {
  error: string | null;
  token: string | null;
  loading: boolean;
}

const initialState: PushNotificationTokenState = {
  error: null,
  token: null,
  loading: false,
};

export const sendPushNotificationToken = createAsyncThunk(
  'pushNotificationToken/sendPushNotificationToken',
  async (params: PushNotificationTokenOmitted, { rejectWithValue }) => {
    try {
      const response =
        await pushNotificationTokenService.sendPushNotificationToken(params);
      // console.log(`Response: ${response}`);
      return params;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const pushNotificationTokenSlice = createSlice({
  name: 'pushNotificationToken',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendPushNotificationToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendPushNotificationToken.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.token;
      })
      .addCase(sendPushNotificationToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default pushNotificationTokenSlice.reducer;
