import {
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { setStatusChangeDialog } from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCircle } from 'react-icons/fa6';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { EventStatus } from 'services/@types';

interface StatusDropdownProps {
  status: EventStatus;
  onStatusChange: (newStatus: EventStatus) => void;
  disabled?: boolean;
  filteredStatuses?: EventStatus[];
  event?: any;
}

const StatusDropdown = ({
  status,
  filteredStatuses,
  onStatusChange,
  disabled,
  event,
}: StatusDropdownProps) => {
  const { t, i18n } = useTranslation();
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const { updatingEventId } = useSelector((state: RootState) => state.event);
  const dispatch = useDispatch();

  const statusOptions = useMemo(
    () => ['new', 'active', 'post', 'completed', 'cancelled'] as EventStatus[],
    [],
  );

  const handleMenuItemClick = useCallback(
    (e: React.MouseEvent, newStatus: EventStatus) => {
      e.stopPropagation();
      onToggle();

      if (status !== newStatus) {
        if (
          true
          //   newStatus === 'active' &&
          //   event?.endDate &&
          //   moment(event.endDate).isBefore(moment())
        ) {
          console.log('open modal', event, newStatus);
          dispatch(
            setStatusChangeDialog({
              item: {
                ...event,
                newStatus,
              },
              onConfirm: () => {
                onStatusChange(newStatus);
              },
            }),
          );
          return;
        }
      }
    },
    [status, onStatusChange, onToggle, dispatch, event],
  );

  const getArrowRotation = useCallback(() => {
    const isRTL = i18n.dir() === 'rtl';
    if (isOpen) {
      return '0deg';
    }
    return isRTL ? '90deg' : '-90deg';
  }, [isOpen, i18n]);

  return (
    <Menu isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
      <MenuButton
        // onClick={handleMenuButtonClick}
        disabled={disabled}
        as={Flex}
        bg={`eventStatus.${status}`}
        borderRadius="8px"
        flex={1}
        justifyContent="center"
        alignItems="center"
        display="flex"
        p="6px 0"
        cursor={disabled ? 'default' : 'pointer'}
        _hover={{
          opacity: disabled ? 1 : 0.8,
        }}>
        <HStack
          pe={2}
          display={updatingEventId === event?.id ? 'none' : 'flex'}>
          <Text w="100%" textAlign="center" variant="eventGridCardStatus">
            {t(`event.status.${status}`)}
          </Text>
          <Icon
            as={MdKeyboardArrowDown}
            color="white"
            transition="all 0.2s ease-in-out"
            transform={`rotate(${getArrowRotation()})`}
          />
        </HStack>
        {updatingEventId === event?.id && (
          <Flex w="100%" justifyContent="center">
            <Spinner size="sm" color="white" alignSelf={'center'} />
          </Flex>
        )}
      </MenuButton>

      <MenuList
        minW="0"
        w="154px"
        layerStyle={{
          width: '40px',
        }}
        py={0}
        borderRadius="8px"
        onClick={(e) => e.stopPropagation()}>
        {statusOptions.map((statusOption, index) => (
          <MenuItem
            key={statusOption}
            onClick={(e) => handleMenuItemClick(e, statusOption)}
            borderTopEndRadius={index === 0 ? '8px' : '0'}
            borderTopStartRadius={index === 0 ? '8px' : '0'}
            borderBottomEndRadius={
              index === statusOptions.length - 1 ? '8px' : '0'
            }
            borderBottomStartRadius={
              index === statusOptions.length - 1 ? '8px' : '0'
            }
            bg={status === statusOption ? `gray.400` : 'transparent'}
            justifyContent="flex-start"
            gap="8px"
            py={2}
            _hover={{
              bg: `gray.300`,
              opacity: 0.8,
            }}>
            <Icon
              fontSize={'xs'}
              as={FaCircle}
              color={`eventStatus.${statusOption}`}
            />
            <Text
              variant="eventGridCardStatus"
              w="fit-content"
              color="black"
              opacity={0.6}>
              {t(`event.status.${statusOption}`)}
            </Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default StatusDropdown;
