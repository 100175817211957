import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import Card from 'components/card/Card';
import { RootState } from 'contexts/redux/store';
import { getTasks } from 'contexts/redux/task/taskSlice';
import { motion } from 'framer-motion';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IMeeting, ITask } from 'services/@types';
import TaskCard from '../../dashboard/components/TaskCard';
import TaskHistoryIcon from '../../../../assets/svg/event/clockRewind.svg';
import MeetingService from 'services/meeting.api';
import MeetingCard from 'views/main/tasks/taskHistory/components/MeetingCard';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';

export default function TaskList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { style, ref } = useHighlightAnimation('tasks');
  const params = useParams();
  const { tasks } = useSelector((state: RootState) => state.task);
  const dispatch = useDispatch<any>();

  const { isLoading } = useQuery({
    queryKey: ['tasks', params?.id],
    queryFn: async () =>
      await dispatch(
        getTasks({
          event: params?.id,
          status: 'pending',
        }),
      ),
    enabled: !!params?.id,
  });

  const todayMeetings = useQuery({
    queryKey: ['todayMeetings', params?.id],
    queryFn: async () =>
      await MeetingService.getMeetings({
        eventId: params?.id,
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
      }),
    enabled: !!params?.id,
  });
  console.log(todayMeetings.data);

  const Tasks = memo(() => {
    return (
      <>
        <HStack justifyContent="space-between" mb="8px"></HStack>
        {isLoading && (
          <HStack justifyContent="center" height="auto" align="center">
            <Spinner />
          </HStack>
        )}
        <VStack gap="8px" pb="17px">
          {tasks?.length === 0 && todayMeetings.data?.results.length === 0 && (
            <VStack pt="50px" height="100%" w="100%">
              <Text variant="taskCardSubTitle">{t('tasks.no_tasks')}</Text>
              <Button
                onClick={() =>
                  navigate(`/main/tasks/create?eventId=${params?.id}`)
                }
                variant="h1cta">
                {t('tasks.create_new_task')}
              </Button>
            </VStack>
          )}
          {todayMeetings.data?.results.map((meeting: IMeeting) => (
            <MeetingCard key={meeting.id} meeting={meeting} />
          ))}
          {tasks?.map((task: ITask) => (
            <TaskCard key={task.id} task={task} />
          ))}
        </VStack>
      </>
    );
  });
  return (
    <Card
      {...style}
      ref={ref}
      flexDirection="column"
      w="100%"
      // minW={310}
      flex={1}
      borderRadius={{ base: 0, md: '30px' }}
      p="31px 26px 0"
      height="100%"
      // maxH="580px"
      overflow="hidden"
      gap={3}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text variant="cardTitle">{t('tasks.title')}</Text>
        <Flex align="center" gap="15px">
          <Flex
            w="24px"
            h="24px"
            bg="#8E7A7033"
            align="center"
            justify="center"
            borderRadius="6px"
            transition="all 0.2s ease-in-out"
            _hover={{ opacity: 0.6 }}
            onClick={() => navigate(`/main/event/${params?.id}/tasks-history`)}>
            <Image
              src={TaskHistoryIcon}
              alt="history"
              w="17px"
              h="17px"
              minW="17px"
              cursor="pointer"
            />
          </Flex>
          <IconButton
            bg="brand.400"
            aria-label="plus"
            w="24px"
            h="24px"
            minW="24px"
            cursor="pointer"
            onClick={() => navigate(`/main/tasks/create?eventId=${params?.id}`)}
            icon={<Icon as={FaPlus} color="warmWhite.100" />}
          />
        </Flex>
      </Flex>

      <Flex
        pt="20.5px"
        as={motion.div}
        initial={{ opacity: 0, scale: 0.98 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.98 }}
        flexDirection="column">
        <Tasks />
      </Flex>
    </Card>
  );
}
