import { AxiosInstance, AxiosResponse } from 'axios';
import { ITrackedEvent, PaginatedResults } from './@types';
import { createAxiosInstance } from './axios.util';

class TrackedEventService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createTrackedEvent(
    ITrackedEventRequest: ITrackedEvent,
  ): Promise<ITrackedEvent> {
    const response: AxiosResponse<ITrackedEvent> = await this.api.post(
      '/tracked-events',
      ITrackedEventRequest,
    );
    return response.data;
  }

  public async getTrackedEvents(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<ITrackedEvent>> {
    const response: AxiosResponse<PaginatedResults<ITrackedEvent>> =
      await this.api.get('/tracked-events', {
        params: queryParams,
      });
    return response.data;
  }

  public async getTrackedEvent(id: string): Promise<ITrackedEvent> {
    const response: AxiosResponse<ITrackedEvent> = await this.api.get(
      `/tracked-events/${id}`,
    );
    return response.data;
  }

  public async updateTrackedEvent(
    id: string,
    updates: Partial<ITrackedEvent>,
  ): Promise<ITrackedEvent> {
    const response: AxiosResponse<ITrackedEvent> = await this.api.patch(
      `/tracked-events/${id}`,
      updates,
    );
    return response.data;
  }

  public async deleteTrackedEvent(id: string): Promise<void> {
    await this.api.delete(`/tracked-events/${id}`);
  }
}

const _ITrackedEventService = new TrackedEventService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _ITrackedEventService;
