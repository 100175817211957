import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from '../store';

interface CreateMeetingState {
  selectedDate: { start: Date; end: Date };
}

const initialState: CreateMeetingState = {
  selectedDate: {
    start: new Date(),
    end: moment(new Date()).add(1, 'hour').toDate(),
  },
};

const createMeetingSlice = createSlice({
  name: 'createMeeting',
  initialState,
  reducers: {
    setSelectedDate: (
      state,
      action: PayloadAction<{ start: Date; end: Date }>,
    ) => {
      state.selectedDate = action.payload;
    },
  },
});

export const { setSelectedDate } = createMeetingSlice.actions;
export const selectSelectedDate = (state: RootState) =>
  state.createMeeting.selectedDate;

export default createMeetingSlice.reducer;
