import { AxiosInstance, AxiosResponse } from 'axios';
import { IMessage, PaginatedResults } from './@types';
import { createAxiosInstance } from './axios.util';

interface GetMessagePageParams {
  threadId: string;
  messageId: string;
}

interface SearchMessagesParams {
  threadId: string;
  query: string;
  cursor?: string;
  direction?: 'next' | 'previous';
  limit?: number;
}

class threadService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createMessage(message: IMessage): Promise<IMessage> {
    const response: AxiosResponse<IMessage> = await this.api.post(
      '/messages',
      message,
    );
    return response.data;
  }

  public async getMessages(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<IMessage>> {
    const response: AxiosResponse<PaginatedResults<IMessage>> =
      await this.api.get('/messages', {
        params: queryParams,
      });
    return response.data;
  }

  public async getMessage(threadId: string): Promise<IMessage> {
    const response: AxiosResponse<IMessage> = await this.api.get(
      `/messages/${threadId}`,
    );
    return response.data;
  }

  public async updateMessage(
    threadId: string,
    updates: Partial<IMessage>,
  ): Promise<IMessage> {
    const response: AxiosResponse<IMessage> = await this.api.patch(
      `/messages/${threadId}`,
      updates,
    );
    return response.data;
  }

  public async deleteMessage(threadId: string): Promise<void> {
    await this.api.delete(`/messages/${threadId}`);
  }

  public async searchMessages({
    threadId,
    query,
    limit = 20,
  }: SearchMessagesParams): Promise<PaginatedResults<IMessage>> {
    const response = await this.api.get('/messages/search', {
      params: {
        threadId,
        query,
        limit,
      },
    });
    return response.data;
  }

  public async getMessagePage(
    params: GetMessagePageParams,
  ): Promise<PaginatedResults<IMessage>> {
    const response = await this.api.get('/messages/page', {
      params: {
        threadId: params.threadId,
        messageId: params.messageId,
      },
    });
    return response.data;
  }
}

const _threadService = new threadService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _threadService;
