import { Icon } from '@chakra-ui/icon';
import { Flex, HStack, Text } from '@chakra-ui/layout';
import {
  openAlertDialog,
  closeAlertDialog,
  setCalendarEventDetailsDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { deleteEvent } from 'contexts/redux/event/eventSlice';
import { format } from 'date-fns';

import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaX } from 'react-icons/fa6';
import { IoLocationOutline } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CalendarEvent, IEvent } from 'services/@types';

export default function EventCard(props: {
  event?: IEvent;
  isDelete?: boolean;
  calendarEvent?: CalendarEvent;
}) {
  const { event, calendarEvent, isDelete = false } = props;
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const time = useMemo(() => {
    if (event?.dateAndTime) {
      return format(new Date(event.dateAndTime), 'HH:mm');
    }

    if (calendarEvent?.start) {
      return format(new Date(calendarEvent.start), 'HH:mm');
    }

    return '';
  }, [event?.dateAndTime, calendarEvent?.start]);

  const onDeleteEvent = useCallback(
    (id: string) => {
      dispatch(
        openAlertDialog({
          title: t('dialogs.delete_event.title'),
          message: t('dialogs.delete_event.description'),
          onConfirm: (index: number) => {
            dispatch(deleteEvent(id));
            dispatch(closeAlertDialog({ index }));
          },
        }),
      );
    },
    [dispatch, t],
  );
  const handleEventClicked = useCallback(() => {
    console.log('event clicked ->', event || calendarEvent);
    dispatch(setCalendarEventDetailsDialog({ item: calendarEvent }));
  }, [calendarEvent, dispatch, event]);

  return (
    <HStack
      borderColor={calendarEvent?.color || '#AA915D'}
      borderRadius="20px"
      borderStyle="solid"
      onClick={handleEventClicked}
      borderWidth="1px"
      w="100%"
      padding="14px 24px">
      <Flex flexDirection="column" alignItems="start" w="100%">
        <Flex gap="2px">
          <Text variant="dashboardEventCardTime">{time}</Text>
          <Text
            variant="dashboardEventCardTitle"
            noOfLines={2}
            cursor="pointer"
            onClick={() =>
              navigate(
                `/main/managment/events/update/${
                  calendarEvent ? calendarEvent.id : event.id
                }`,
              )
            }>
            {event?.name || calendarEvent?.summary}
          </Text>
        </Flex>
        {event?.location?.label ||
          (calendarEvent?.location && (
            <HStack>
              <Icon as={IoLocationOutline} color="#AA915D" />
              <Text
                color="#AA915D"
                noOfLines={1}
                pt={0}
                fontWeight="400"
                fontSize={{ base: 'sm', sm: 'xs', '2xl': 'sm' }}
                wordBreak={'break-word'}>
                {event?.location?.label || calendarEvent?.location}
              </Text>
            </HStack>
          ))}
      </Flex>
      {isDelete && (
        <Icon
          as={FaX}
          color="#AA915D"
          w="10px"
          h="10px"
          cursor="pointer"
          onClick={() => onDeleteEvent(event?.id || calendarEvent?.id)}
        />
      )}
    </HStack>
  );
}
