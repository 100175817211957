import {
  Avatar,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import GoogleCalendarImg from 'assets/img/calendar/meet-icon.png';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import {
  closeCalendarEventDetailsDialog,
  setCalendarEventDetailsDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { getMeeting } from 'contexts/redux/meeting/meetingSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';

import { format } from 'date-fns';
import { enGB, he } from 'date-fns/locale';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEdit3, FiMapPin, FiX } from 'react-icons/fi';
import { RxCopy } from 'react-icons/rx';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Calendar, CalendarEvent, GoogleCalendarEvent } from 'services/@types';

const CalendarEventDetailsModal: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const user = useMyUser();
  const toast = useToast();

  const { calendarEventDetailsDialog } = useSelector(
    (state: RootState) => state.dialogs,
  );
  const isOpen = !!calendarEventDetailsDialog;
  const event: CalendarEvent | undefined = calendarEventDetailsDialog?.item;
  const { calendar }: { calendar: Calendar | undefined } = useSelector(
    (state: RootState) => ({
      calendar: state.calendars.calendars?.find(
        (c) => c.id === event?.calendarId,
      ),
    }),
  );

  const handleClose = useCallback(() => {
    dispatch(closeCalendarEventDetailsDialog());
  }, [dispatch]);

  const handleEdit = useCallback(() => {
    // Implement edit functionality
    console.log('Edit event:', event?.id);
    if (event?.category === 'event') {
      navigate(`/main/managment/events/update/${event?.id}`);
    } else if (event?.category === 'meeting') {
      navigate(`/main/meetings/update/${event?.id}`);
      dispatch(getMeeting(event.id!));
    }
    dispatch(setCalendarEventDetailsDialog(event));
  }, [event, navigate, dispatch]);

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(
      (event?.data as GoogleCalendarEvent)?.hangoutLink,
    );
    toast({
      title: t('calendar.link_copied'),
      status: 'success',
      variant: 'main',
    });
  }, [event?.data, t, toast]);
  const canEdit = useMemo(() => {
    if (calendar?.type === 'internal' && calendar?.name !== 'holidays') {
      return true;
    } else if (
      calendar?.integrationId &&
      (event.data as GoogleCalendarEvent)?.attendees?.find(
        (a) => a.email === user?.email,
      )?.organizer
    ) {
      return true;
    }
    if (event?.category === 'event') {
      return true;
    }
    return false;
  }, [
    calendar?.integrationId,
    calendar?.name,
    calendar?.type,
    event?.category,
    event?.data,
    user?.email,
  ]);
  const formattedDate = useMemo(() => {
    if (!event?.start) return '';
    const locale = i18n.language.startsWith('he') ? he : enGB;
    return format(new Date(event?.start || ''), 'EEE, MM/dd/yyyy', {
      locale,
    });
  }, [event?.start, i18n?.language]);

  const formattedTime = useMemo(() => {
    if (event?.start && event?.end) {
      const startTime = format(new Date(event?.start || ''), 'HH:mm');
      const endTime = format(new Date(event?.end || ''), 'HH:mm');
      return `${startTime} - ${endTime}`;
    }
    return '';
  }, [event?.start, event?.end]);

  if (!event) return null;

  return (
    <Modal
      isOpen={isOpen}
      motionPreset="slideInBottom"
      onClose={handleClose}
      scrollBehavior="outside"
      size="md">
      <ModalOverlay />
      <ModalContent
        animation={''}
        borderRadius={{ base: 0, md: '16px' }}
        maxW="430px"
        alignSelf={{ base: 'end', md: 'center' }}
        margin={{ base: 0, md: 4 }}
        zIndex={2200}
        h={{ base: '80vh', md: 'auto' }}
        maxH={{ base: '100vh', md: undefined }}>
        <ModalHeader px={4}>
          <HStack>
            <Flex w="4" h="4" borderRadius="50%" bg={calendar?.color} />
            <Text fontSize="sm" color="black">
              {calendar?.isSystem
                ? t(`calendar.general_calendars.${calendar?.name}`)
                : calendar?.name || calendar?.email}
            </Text>
            <Spacer />

            <HStack>
              <IconButton
                display={canEdit ? 'flex' : 'none'}
                variant="actionIconTransparent"
                icon={<Icon as={FiEdit3} color="brand.400" fontSize={'24px'} />}
                onClick={handleEdit}
                aria-label="Edit"
              />
              <IconButton
                variant="ghost"
                opacity={0.6}
                icon={<FiX fontSize={'24px'} />}
                onClick={handleClose}
                aria-label="Close"
              />
            </HStack>
          </HStack>

          <Flex justifyContent="space-between" alignItems="center">
            <Text fontSize="2xl" fontWeight="400">
              {event.title}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalBody px={4} pb={4} h="100%" overflow="hidden" display={'flex'}>
          <VStack align="stretch" spacing={2}>
            <HStack>
              <Text color="brand.300" fontWeight={'700'} fontSize="sm">
                {formattedDate}
              </Text>
              <Text color="brand.900" fontWeight={'500'} fontSize="md">
                {formattedTime}
              </Text>
            </HStack>
            {event.location && (
              <HStack>
                <FiMapPin />
                <Text>{event.location}</Text>
              </HStack>
            )}
            {event.description && (
              <Text opacity={0.6}>{event.description}</Text>
            )}
            <HStack
              spacing={4}
              align="start"
              display={
                (event.data as GoogleCalendarEvent)?.hangoutLink
                  ? 'flex'
                  : 'none'
              }>
              <Image w="40px" h="32px" mt={2} src={GoogleCalendarImg} />
              <VStack align="start" spacing={0}>
                <Button
                  color="white"
                  px={6}
                  h="45px"
                  bg="#1A73E7"
                  size="sm"
                  onClick={() => {
                    window.open(
                      (event?.data as GoogleCalendarEvent)?.hangoutLink,
                      '_blank',
                    );
                  }}>
                  {t('calendar.join_google_meet')}
                </Button>
                <HStack spacing={0}>
                  <Text
                    opacity={0.6}
                    cursor="pointer"
                    textDecorationLine="underline"
                    onClick={() => {
                      window.open(
                        (event?.data as GoogleCalendarEvent)?.hangoutLink,
                        '_blank',
                      );
                    }}>
                    {(event?.data as GoogleCalendarEvent)?.hangoutLink}
                  </Text>
                  <IconButton
                    variant="ghost"
                    onClick={handleCopyLink}
                    opacity={0.6}
                    icon={<RxCopy />}
                    aria-label="Add"
                  />
                </HStack>
              </VStack>
            </HStack>
            <Divider />
            {(event.data as GoogleCalendarEvent)?.attendees?.length > 0 && (
              <VStack align="stretch" spacing={2} overflow={'scroll'}>
                <HStack>
                  <Text fontWeight="bold" fontSize={'sm'}>
                    {t('calendar.guests')}
                  </Text>
                </HStack>
                <VStack spacing={2} align="start" h="100%" overflow={'scroll'}>
                  {[...(event.data as GoogleCalendarEvent)?.attendees]?.map(
                    (a) => (
                      <HStack>
                        <Avatar size={'sm'} key={a.email} name={a.email} />
                        <Text>{a.email}</Text>
                      </HStack>
                    ),
                  )}
                  {/* Replace with actual attendees data */}
                </VStack>
              </VStack>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CalendarEventDetailsModal;
