import { Button, Flex, Image, Text, useToast, VStack } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import Icon from 'assets/svg/notification/askingForQuote.svg';
import EventDueIcon from 'assets/svg/notification/event-due-icon.svg';
import axios from 'axios';
import { updateNotification } from 'contexts/redux/notifications/notificationsSlice';
import { AppDispatch } from 'contexts/redux/store';
import moment from 'moment';
import 'moment/locale/he';
import { createEntityHook } from 'queries/entity';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { INotification } from 'services/@types';
import { AppNotificationEventName } from 'services/@types/notificationEventNames';
import { getNotificationMomentFormat } from './NotificationComponent';

export default function ButtonNotification({
  notification,
  notificationRef,
}: {
  notification: INotification;
  notificationRef: React.RefObject<HTMLDivElement>;
}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const queryClient = useQueryClient();
  const toast = useToast();
  const icon = useMemo(() => {
    if (
      notification.notificationEventName ===
      AppNotificationEventName.EventDuePassed
    ) {
      return EventDueIcon;
    }
    return Icon;
  }, [notification.notificationEventName]);

  const entity = createEntityHook(notification?.type);

  const entityQuery: any = entity(notification?.entityId || null, {
    enabled: !!notification?.entityId,
  }).data;

  const handleButtonClick = useCallback(() => {
    if (!notification.buttonLink) return;
    axios
      .get(notification.buttonLink)
      .then(() => {
        dispatch(
          updateNotification({
            notificationId: notification.id,
            updates: { visible: false },
          }),
        );
        entity.invalidate(queryClient, notification?.entityId);
        toast({
          title: t('toast.title.operation_succeeded'),
          variant: 'green',
          status: 'success',
        });
      })
      .catch((e) => {
        console.warn(`failed to click button: ${e}`);
      });
  }, [
    notification.buttonLink,
    notification.id,
    notification?.entityId,
    dispatch,
    entity,
    queryClient,
    toast,
    t,
  ]);
  const handleButtonUnderlineClick = useCallback(() => {
    if (!notification.buttonUnderlineLink) return;

    axios
      .get(notification.buttonUnderlineLink)
      .then(() => {
        entity.invalidate(queryClient, notification?.entityId);
        toast({
          title: t('toast.title.operation_succeeded'),
          variant: 'green',
          status: 'success',
        });
      })
      .catch((e) => {
        console.warn(`failed to click button underline: ${e}`);
      });
  }, [
    notification.buttonUnderlineLink,
    notification?.entityId,
    entity,
    queryClient,
    toast,
    t,
  ]);

  // Set moment locale based on current language
  moment.locale(i18n.language.startsWith('he') ? 'he' : 'en');

  return (
    <Flex
      ref={notificationRef}
      border="1px solid #F9FAFB"
      borderRadius="12px"
      p="8px 8px"
      flexDirection="column"
      gap="36px">
      <Flex alignItems="start" gap="12px" p="0 5px">
        <Image src={icon} alt="" w="24px" h="24px" />
        <Text variant="cardSubTitle" color="#1B2037" fontSize="sm">
          {/* <span style={{ fontWeight: 700 }}>{'invite?.fullName' || 'Ana'}</span>{' '}
          is asking for a quote */}
          {t(notification.message, {
            name: notification.variables?.name,
          })}
        </Text>
      </Flex>
      <VStack gap="12px">
        <VStack spacing={2} w="100%">
          <Button variant="h1cta" w="100%" onClick={handleButtonClick}>
            {/* View request */}
            {t(notification.buttonText)}
          </Button>
          <Button
            variant="h4"
            textDecorationLine={'underline'}
            fontWeight="500"
            display={notification.buttonUnderlineLink ? 'block' : 'none'}
            w="100%"
            onClick={handleButtonUnderlineClick}>
            {t(notification.buttonUnderlineText)}
          </Button>
        </VStack>
        <Text fontSize="md" color="gray.500" alignSelf="flex-end">
          {/* 8 Feb */}
          {moment(notification.createdAt).format(
            getNotificationMomentFormat(i18n.language),
          )}
        </Text>
      </VStack>
    </Flex>
  );
}
