// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  HStack,
  Icon,
  Image,
  Show,
  Spacer,
  Text,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
// Custom components
import { HSeparator } from 'components/separator/Separator';
import DefaultAuth from 'layouts/auth/variants/Default';
// Assets
import Illustration from 'assets/img/auth/sing_in.jpeg';
import InputFieldPassword from 'components/fields/InputFieldPassword';
import { useTranslation } from 'react-i18next';
import { FcGoogle } from 'react-icons/fc';
import _authService from 'services/auth.api';
import Logo from '../../../assets/svg/brilliant-logo.svg';
import { useSignIn } from '../signIn/hooks/useSignIn';
import { useResetPassword } from './hooks/useResetPassword';

function ResetPassword() {
  const { t } = useTranslation();
  // Chakra color mode
  const googleHover = useColorModeValue(
    { bg: 'gray.200' },
    { bg: 'whiteAlpha.300' },
  );
  const googleActive = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.200' },
  );

  const { handleChange, error, loading, resetPassword, inputValues } =
    useResetPassword();

  const { signInWithGoogle } = useSignIn();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  return (
    <DefaultAuth image={Illustration}>
      <Flex
        w={{ base: '100%', lg: '50%' }}
        h="100%"
        justifyContent="center"
        flexDirection="column"
        gap={10}
        align={{ base: 'center' }}
        // px={{ base: '20px', sm: '20px', md: '60px', lg: '120px', xl: '150px' }}
      >
        <Spacer />
        <VStack
          gap={0}
          align="flex-start"
          flex={1}
          maxW={320}
          justify="end"
          w="100%">
          <Show above="md">
            <Image
              src={Logo}
              mb={2}
              alt="logo"
              w="217px"
              onClick={() => {
                _authService.signOutWithGoogle();
              }}
            />
          </Show>
          <Text fontSize={'2xl'} fontWeight={'bold'}>
            {t('reset_password.title')}
          </Text>
          <Text color="primary.100" fontWeight="400" fontSize="md">
            {t('reset_password.description')}
          </Text>
        </VStack>
        <VStack
          w="100%"
          flex={0.5}
          maxW={330}
          align="start"
          justify={'end'}
          gap={1}>
          <FormControl
            gap={{ base: 6, lg: 6 }}
            display="flex"
            flexDirection={'column'}>
            <VStack spacing={2} justify="start">
              <InputFieldPassword
                id="new-password-input"
                isRequired={true}
                label={t('reset_password.new_password') + '*'}
                w="100%"
                bg={'transparent'}
                type="password"
                showButton
                placeholder={t('reset_password.new_password_placeholder')}
                variant="auth"
                showValidations={!isMobile}
                name="password"
                onChange={handleChange}
                value={inputValues?.password || ''}
              />
              <Spacer maxH={6} />

              <InputFieldPassword
                id="confirm-password-input"
                isRequired={true}
                label={t('reset_password.repeat_password') + '*'}
                w="100%"
                bg={'transparent'}
                showValidations={!isMobile}
                type="password"
                showButton
                placeholder={t('reset_password.repeat_password_placeholder')}
                variant="auth"
                name="confirmPassword"
                onChange={handleChange}
                value={inputValues?.confirmPassword || ''}
              />
            </VStack>
            <Button
              isLoading={loading}
              variant="h1cta"
              w="100%"
              onClick={resetPassword}>
              {t('reset_password.update_password')}
            </Button>

            <HStack w="100%" py={6}>
              <HSeparator bg="#00000066" flex={1} />
              <Text color="#00000066" px={'10px'} mx="24px">
                {t('signin.or')}
              </Text>
              <HSeparator bg="#00000066" flex={1} />
            </HStack>
            <Button
              variant="google"
              w="100%"
              onClick={signInWithGoogle}
              _hover={googleHover}
              _active={googleActive}
              _focus={googleActive}>
              <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
              {t('signin.sign_in_with_google')}
            </Button>
          </FormControl>
          <Text color="red.500" fontSize="sm" textAlign="center" w="100%">
            {error && t(error)}
          </Text>
        </VStack>
        <Spacer />
      </Flex>
    </DefaultAuth>
  );
}

export default ResetPassword;
