import { Flex, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { IEvent } from 'services/@types';
import eventsService from 'services/event.api';
import GridEventCard from 'views/main/managment/events/eventsOverview/components/GridEventCard';
const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};
interface UpcomingEventsProps {
  cardStyles?: any;
}
export default function UpcomingEvents(props: UpcomingEventsProps) {
  const { cardStyles = {} } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const events = useQuery({
    queryKey: ['dashboardUpcomingEvents'],
    queryFn: () =>
      eventsService.getEvents({
        upcomingDate: new Date(),
        limit: 6,
        sortBy: '-createdAt',
      }),
  }).data;

  return (
    <Card
      flex={1}
      minWidth="200px"
      maxH="580px"
      gap="20px"
      borderRadius={{ base: '0px', md: '18px' }}
      {...cardStyles}>
      <Flex alignItems="center" justifyContent="space-between">
        <Text variant="cardTitle">{t('upcoming_events.title')}</Text>
        <Text
          variant="eventSubTitle"
          textDecoration="underline"
          onClick={() => navigate('/main/managment/events')}
          cursor="pointer">
          {t('upcoming_events.see_all')}
        </Text>
      </Flex>
      <AnimatePresence mode="wait">
        <motion.div
          variants={container}
          initial="hidden"
          style={{
            width: 'inherit',
            height: 'inherit',
            overflow: 'auto',
          }}
          animate="visible">
          <motion.div
            variants={item}
            style={{ width: 'inherit', height: 'inherit' }}>
            <Flex wrap="wrap" overflowY="auto" gap="12px">
              {events?.results?.map((event: IEvent, index: number) => (
                <GridEventCard key={index} event={event} />
              ))}
            </Flex>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </Card>
  );
}
