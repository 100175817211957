import {
  Flex,
  HStack,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { UserPreferences } from 'services/@types';
const notificationHistoryDurations = ['1 week', '2 weeks', '1 month'];
export default function KeepHistory(props: {
  notificationHistoryDuration: '1 week' | '2 weeks' | '1 month';
  handleUpdateMyUserPreferences: (
    newPreferences: Partial<UserPreferences>,
  ) => void;
}) {
  const { notificationHistoryDuration, handleUpdateMyUserPreferences } = props;
  const { t } = useTranslation();

  return (
    <Flex flexDir="column" alignItems="start" gap="16px">
      <HStack gap="16px">
        <Text variant="settingItemTitle">
          {t(`notification_settings_item.title.keep_history`)}
        </Text>
      </HStack>
      <RadioGroup
        onChange={(e: '1 week' | '2 weeks' | '1 month') => {
          handleUpdateMyUserPreferences({ notificationHistoryDuration: e });
        }}
        value={notificationHistoryDuration}>
        <VStack alignItems="start" fontWeight="500" gap="16px">
          <Radio variant="brandOutline" value={notificationHistoryDurations[0]}>
            {t('notification_settings_item.1_w')}
          </Radio>
          <Radio variant="brandOutline" value={notificationHistoryDurations[1]}>
            {t('notification_settings_item.2_w')}
          </Radio>
          <Radio variant="brandOutline" value={notificationHistoryDurations[2]}>
            {t('notification_settings_item.1_m')}
          </Radio>
        </VStack>
      </RadioGroup>
    </Flex>
  );
}
