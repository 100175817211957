import {
  Button,
  Divider,
  Flex,
  Grid,
  Hide,
  Image,
  Show,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import DropdownField from 'components/fields/DropdownField';
import { RootState } from 'contexts/redux/store';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NoSuppliersImage from '../../../../../assets/img/noSuppliers/noSuppliers.png';
import ArrowImage from '../../../../../assets/svg/suppliers/arrow-to.svg';
export default function NoSuppliers({
  onOpenAddSupplier,
  filteringCategories,
}: {
  onOpenAddSupplier: () => void;
  filteringCategories?: string[];
}) {
  const { businessCategories } = useSelector((state: RootState) => ({
    businessCategories: state.businessCategories.businessCategories,
  }));
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const secondaryTextColor = useColorModeValue('primary.100', 'whiteAlpha.600');
  const { t } = useTranslation();
  return (
    <Flex
      w="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}
      flexDirection="column"
      gap={4}>
      <Grid
        display={{ base: 'flex', md: 'grid' }}
        gridTemplateColumns={{ base: '1.5fr 1.83fr', md: '1.1fr 1.83fr' }}
        gap={{ base: '10px', xl: '20px' }}
        flexDirection="column"
        h="calc(100vh - 170px)">
        <Hide above="md">
          <Flex justify="space-between" align="center" px={2}>
            <DropdownField
              options={businessCategories?.map(
                (category) => `categories.${category.name}`,
              )}
              placeholder={
                filteringCategories.length > 0
                  ? t('suppliers.categories', {
                      count: filteringCategories.length,
                    })
                  : t('suppliers.all_categories')
              }
              name="category"
              selectedOptions={filteringCategories?.map((sc) => {
                const selectedCategory = businessCategories.find(
                  (bc) => bc.id === sc,
                );
                return `categories.${selectedCategory?.name}`;
              })}
              isDisabled={true}
              menuButton={{ w: { base: '100%', md: '310px' } }}
            />
            <Button onClick={onOpenAddSupplier} variant="h1cta" px={12}>
              {t('suppliers.add_supplier')}
            </Button>
          </Flex>
        </Hide>
        <Card
          p="0px"
          px="0px"
          borderRadius={{ base: '0px', md: '30px' }}
          pt="30px"
          pb="30px"
          minW={{ base: '100%', lg: '500px' }}>
          <VStack gap={0}>
            <Flex
              w="100%"
              py={4}
              px={6}
              gap={6}
              flex={1}
              cursor="pointer"
              align="center">
              <Flex direction="column" flex={1}>
                <VStack
                  align="start"
                  spacing={0}
                  flex={1}
                  wordBreak={'break-all'}>
                  <Text
                    color={textColor}
                    fontSize={{ base: 'xl', sm: 'md', '2xl': '2xl' }}
                    noOfLines={1}
                    fontWeight="700">
                    {t('suppliers.add_your_first_supplier')}
                  </Text>
                </VStack>
                <Text
                  color={secondaryTextColor}
                  fontSize={{ base: 'md', sm: 'xs', md: 'sm', '2xl': 'md' }}
                  fontWeight="300"
                  display="flex"
                  noOfLines={2}>
                  {t('suppliers.add_your_first_supplier_description')}
                </Text>
              </Flex>
              <Show above="md">
                <Button onClick={onOpenAddSupplier} variant="h1cta" px={12}>
                  {t('suppliers.add_supplier')}
                </Button>
              </Show>
              <Hide above="md">
                <Image src={ArrowImage} alt="Arrow" />
              </Hide>
            </Flex>
            <Divider
              sx={{
                marginTop: '0px !important',
                w: '90%',
                borderColor: 'gray.300',
              }}
            />
          </VStack>
        </Card>
        <Show above="md">
          <Card
            flexDirection="column"
            alignItems="center"
            gap={10}
            justifyContent="center"
            flex="1"
            p="0px"
            px="0px">
            <Image src={NoSuppliersImage} w="222px" alt="No Suppliers" />
            <VStack maxW="322px" textAlign="center">
              <Text
                color={textColor}
                fontSize={{ base: 'xl', sm: 'md', '2xl': '2xl' }}
                noOfLines={1}
                fontWeight="700">
                {t('suppliers.nosing_here_yet')}
              </Text>
              <Text
                color={secondaryTextColor}
                fontSize={{ base: 'md', sm: 'xs', md: 'sm', '2xl': 'md' }}
                fontWeight="300"
                display="flex"
                noOfLines={4}>
                {t('suppliers.nosing_here_yet_description')}
              </Text>
            </VStack>
          </Card>
        </Show>
      </Grid>
    </Flex>
  );
}
