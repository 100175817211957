import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDBC6PGowOm_CfvBIW-5eVdzGSc4O_B28o',
  authDomain: 'brilliant-crm.firebaseapp.com',
  projectId: 'brilliant-crm',
  storageBucket: 'brilliant-crm.appspot.com',
  messagingSenderId: '876291263637',
  appId: '1:876291263637:web:77bf7dd74fac220bb05f9c',
  measurementId: 'G-GGNK3190N4',
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export { messaging, getToken, onMessage };
