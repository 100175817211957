import { Flex, Image, Text } from '@chakra-ui/react';
import chatsEmptyIcon from 'assets/svg/messenger/chats-empty-icon.svg';
import { useTranslation } from 'react-i18next';
export default function NoConversationFound() {
  const { t } = useTranslation();
  return (
    <Flex
      w="100%"
      h="100%"
      flexDirection="column"
      justifyContent={'center'}
      alignItems="center"
      gap={6}>
      <Image src={chatsEmptyIcon} w={90} h={70} alt="No Conversation Found" />
      <Text fontSize={'md'} fontWeight="bold">
        {t('messenger.no_conversation_found')}
      </Text>

      <Text fontSize="sm" color="primary.100">
        {t('messenger.no_conversation_found_description')}
      </Text>
    </Flex>
  );
}
