import { Flex, HStack, Text } from '@chakra-ui/layout';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'contexts/redux/store';
import { CalendarEvent } from 'services/@types';
import { setCalendarEventDetailsDialog } from 'contexts/redux/dialog/dialogsSlice';

export default function HolidayCard(prors: {
  calendarHoliday?: CalendarEvent;
}) {
  const { calendarHoliday } = prors;
  const dispatch = useDispatch<AppDispatch>();
  return (
    <HStack
      borderColor={calendarHoliday?.color}
      borderRadius="16px"
      borderStyle="solid"
      borderWidth="1px"
      w="100%"
      padding="14px 24px"
      cursor="pointer"
      onClick={() => {
        dispatch(setCalendarEventDetailsDialog({ item: calendarHoliday }));
      }}>
      <Flex flexDirection="column" alignItems="start" w="100%">
        <Text
          variant="cardSectionInfo"
          ps={0}
          wordBreak="break-all"
          noOfLines={1}>
          {calendarHoliday?.summary}
        </Text>
      </Flex>
    </HStack>
  );
}
