import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { IEvent } from 'services/@types';
import _eventService from 'services/event.api';

interface UseGetEventOptions {
  enabled?: boolean;
}

const getEventQueryKey = (eventId: string): QueryKey => ['event', eventId];

const getEventQueryOptions = (eventId: string) => ({
  queryKey: getEventQueryKey(eventId),
  queryFn: () => _eventService.getEvent(eventId),
  staleTime: 0, // disable stale time
  gcTime: 0, // disable gc time
  retry: (failureCount: number, error: any) => {
    return failureCount < 3 && error.response?.status !== 404;
  },
});

const useGetEvent = (
  eventId: string,
  options: UseGetEventOptions = {},
): UseQueryResult<IEvent, Error> => {
  return useQuery({
    ...getEventQueryOptions(eventId),
    enabled: options.enabled !== false && !!eventId,
  });
};

// Helper methods for prefetching and invalidating
useGetEvent.prefetch = async (
  queryClient: ReturnType<typeof useQueryClient>,
  eventId: string,
) => {
  await queryClient.prefetchQuery(getEventQueryOptions(eventId));
};

useGetEvent.invalidate = (
  queryClient: ReturnType<typeof useQueryClient>,
  eventId: string,
) => {
  queryClient.invalidateQueries({ queryKey: getEventQueryKey(eventId) });
};

export default useGetEvent;
