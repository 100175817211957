import { getEventsResult } from 'contexts/redux/event/eventSlice';
import { RootState } from 'contexts/redux/store';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const cleanParams = (params: Record<string, any>) => {
  // Create a new object excluding empty string values
  return Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value !== ''),
  );
};

export default function useEvents() {
  const dispatch = useDispatch<any>();
  const { result, filter, pagination, isLoading } = useSelector(
    (state: RootState) => ({
      result: state.event.eventsResult.result,
      filter: state.event.eventsResult.filter,
      pagination: state.event.eventsResult.pagination,
      isLoading: state.event.loading,
    }),
  );
  const isFilterEmpty = useMemo(
    () =>
      Object.values(filter).every((value) => {
        return value === '';
      }),
    [filter],
  );
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const cleanedFilter = cleanParams(filter);
        const paginationParams = {
          page: pagination.page,
          limit: pagination.limit,
        };
        await dispatch(
          getEventsResult({ ...cleanedFilter, ...paginationParams }),
        );
      } catch (error) {
        console.error(error);
      }
    };
    fetchEvents();
  }, [dispatch, filter, pagination.page, pagination.limit]);
  return { result, isFilterEmpty, isLoading };
}
