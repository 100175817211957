import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { createAxiosInstance } from '../services/axios.util';

const axiosInstance = createAxiosInstance(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

// Helper to convert camelCase to kebab-case
const toKebabCase = (str: string): string => {
  return str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
};

interface UseGetEntityOptions {
  enabled?: boolean;
}

export function createEntityHook<TData>(entityName: string) {
  if (!entityName) {
    throw new Error('Entity name is required');
  }
  const apiEndpoint = `/${toKebabCase(entityName)}s`;

  // Query key creator
  const getQueryKey = (id?: string): QueryKey => [entityName, id];

  // Default get entity function
  const defaultGetEntity = async (id?: string): Promise<TData> => {
    const { data } = await axiosInstance.get(`${apiEndpoint}/${id}`);
    return data;
  };

  // Query options creator
  const getQueryOptions = (id?: string) => ({
    queryKey: getQueryKey(id),
    queryFn: () => defaultGetEntity(id),
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 15 * 60 * 1000, // 15 minutes
    retry: (failureCount: number, error: any) => {
      return failureCount < 3 && error.response?.status !== 404;
    },
  });

  // Main hook
  const useGetEntity = (
    id: string,
    options: UseGetEntityOptions = {},
  ): UseQueryResult<TData, Error> => {
    return useQuery({
      ...getQueryOptions(id),
      enabled: options.enabled !== false && !!id,
    });
  };

  // Utility methods
  useGetEntity.prefetch = async (
    queryClient: ReturnType<typeof useQueryClient>,
    id: string | null,
  ) => {
    await queryClient.prefetchQuery(getQueryOptions(id));
  };

  useGetEntity.invalidate = (
    queryClient: ReturnType<typeof useQueryClient>,
    id: string,
  ) => {
    queryClient.invalidateQueries({ queryKey: getQueryKey(id) });
  };

  return useGetEntity;
}

export const useGetEntity = (entityName: string, id?: string) => {
  const hook = createEntityHook(entityName);
  return hook(id);
};
