import { Flex, Icon, Image, Text } from '@chakra-ui/react';
import ClientFileIcon from 'assets/svg/eventTabButton/clientFileIcon.svg';
import FinanceIcon from 'assets/svg/eventTabButton/financeIcon.svg';
import SupplierAssignmentIcon from 'assets/svg/eventTabButton/supplierAssignmentIcon.svg';
import { setCurrentEventTab } from 'contexts/redux/event/eventSlice';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiGrid } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
interface EventTabButtonProps {
  type?: 'main' | 'client_file' | 'finance' | 'supplier_assignment';
  max: number;
  current: number;
  title: string;
  [key: string]: any;
}
export default function EventTabButton(props: EventTabButtonProps) {
  const { type, current, max, title, ...rest } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch<any>();
  const icon = useMemo(() => {
    switch (type) {
      case 'main':
        return <Icon as={FiGrid} color="#AA915D" />;
      case 'finance':
        return <Image src={FinanceIcon} w="24px" h="24px" alt="" />;
      case 'client_file':
        return <Image src={ClientFileIcon} w="24px" h="24px" alt="" />;
      case 'supplier_assignment':
        return <Image src={SupplierAssignmentIcon} w="24px" h="24px" alt="" />;
      default:
        return <Icon as={FiGrid} color="#AA915D" />;
    }
  }, [type]);
  return (
    <Flex
      boxShadow="0px 0px 24px 0px #7090B026"
      h="64px"
      borderRadius="16px"
      justifyContent="start"
      alignItems="start"
      bg="#FFFFFF"
      position="relative"
      minW="calc(100% / 2 - 18px)"
      flex="1"
      onClick={() => dispatch(setCurrentEventTab(type))}
      {...rest}>
      <Text m="10px" variant="eventTabButtonCount">
        {`${current}/${max}`}
      </Text>
      <Flex
        position="absolute"
        left="50%"
        top="50%"
        transform="translate(-50%, -50%)"
        flexDirection="column"
        alignItems="center"
        w="100%"
        justifyContent="center"
        gap="5px">
        {icon}
        <Text variant="eventTabButtonTitle" textAlign="center">
          {t(title)}
        </Text>
      </Flex>
    </Flex>
  );
}
