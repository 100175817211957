import {
  Flex,
  Hide,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';
import { setQuoteSharingDialog } from 'contexts/redux/dialog/dialogsSlice';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMoreHorizontal } from 'react-icons/fi';
import { IoTriangleSharp } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { IQoute } from 'services/@types';

const headers = ['date', 'event_name', 'amount', 'customer', ''];

export default function QuoteList(props: { quotes: IQoute[] }) {
  const { quotes } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();

  const threeDotsMenu = useMemo(() => {
    return (
      <Menu>
        <MenuButton>
          <Icon as={FiMoreHorizontal} color="#93C3B3" w="24px" h="24px" />
        </MenuButton>
        <MenuList>
          <MenuItem h="44px">
            <Text variant="eventTabButtonTitle">{t('quote_list.view')}</Text>
          </MenuItem>
          <MenuItem
            h="44px"
            onClick={() => dispatch(setQuoteSharingDialog({}))}>
            <Text variant="eventTabButtonTitle">{t('quote_list.share')}</Text>
          </MenuItem>
          <MenuItem h="44px">
            <Text variant="eventTabButtonTitle">
              {t('quote_list.download')}
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }, [dispatch]);

  return (
    <VStack w="100%" spacing={0} align="stretch">
      <Hide below="md">
        <Flex py={4} px={6} bg="white">
          {headers.map((header, index) => (
            <Flex
              key={header}
              flex={index === headers.length - 1 ? '0 0 60px' : 1}
              align="center"
              fontSize="14px"
              fontWeight="400"
              color="#00000099">
              {header ? t(`quote_list.${header.toLowerCase()}`) : ''}
              {header && (
                <Icon as={IoTriangleSharp} color="#93C3B3" h="10px" ml={2} />
              )}
            </Flex>
          ))}
        </Flex>
      </Hide>
      {/* Rows */}
      <VStack spacing={0} align="stretch" overflowY="auto">
        {quotes.map((quote) => (
          <Flex
            key={quote.id}
            justify="space-between"
            py={4}
            px={6}
            borderBottom="1px solid #E6E6E6"
            _hover={{ bg: 'gray.50' }}
            fontSize="14px">
            <Flex
              flex="1"
              flexDirection={{ base: 'column', md: 'row' }}
              justifyContent={{ base: 'flex-start', md: 'space-between' }}
              gap={2}
              fontWeight="500">
              <Hide below="md">
                <Flex flex={1} fontWeight="700">
                  {quote?.createdAt && format(quote.createdAt, 'dd.MM.yy')}
                </Flex>
              </Hide>
              <Flex flex={1} fontWeight={{ base: '700', md: '500' }}>
                {quote.eventId}
              </Flex>
              <Flex flex={1}>₪{quote?.amount?.toLocaleString()}</Flex>
              <Flex flex={1}>{quote.supplierId}</Flex>
              <Hide below="md">
                <Flex flex="0 0 60px" justify="center">
                  {threeDotsMenu}
                </Flex>
              </Hide>
            </Flex>
            <Hide above="md">
              <Flex
                flex="0 1 100px"
                justify={{ base: 'space-between', md: 'flex-start' }}
                flexDirection="column">
                {threeDotsMenu}
                <Flex>
                  {quote?.createdAt && format(quote.createdAt, 'dd MMM, yyyy')}
                </Flex>
              </Flex>
            </Hide>
          </Flex>
        ))}
      </VStack>
    </VStack>
  );
}
