import { Icon } from '@chakra-ui/icon';
import { Flex, HStack, Text, VStack } from '@chakra-ui/layout';
import Avatars from 'components/avatars';
import {
  closeAlertDialog,
  openAlertDialog,
  setCalendarEventDetailsDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { deleteMeeting } from 'contexts/redux/meeting/meetingSlice';
import { format } from 'date-fns';

import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaX } from 'react-icons/fa6';
import { IoLocationOutline } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { CalendarEvent, IMeeting } from 'services/@types';

export default function MeetingCard(prors: {
  meeting?: IMeeting;
  isDelete?: boolean;
  calendarMeeting?: CalendarEvent;
}) {
  const { meeting, calendarMeeting, isDelete = false } = prors;
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const startTime = useMemo(() => {
    if (meeting?.startDate) {
      return format(new Date(meeting.startDate), 'HH:mm');
    }
    if (calendarMeeting?.start) {
      return format(new Date(calendarMeeting.start), 'HH:mm');
    }
    return '';
  }, [meeting?.startDate, calendarMeeting?.start]);
  const endTime = useMemo(() => {
    if (meeting?.endDate) {
      return format(new Date(meeting.endDate), 'HH:mm');
    }
    if (calendarMeeting?.end) {
      return format(new Date(calendarMeeting.end), 'HH:mm');
    }
    return '';
  }, [meeting?.endDate, calendarMeeting?.end]);
  const onDeleteEvent = useCallback(
    (id: string) => {
      dispatch(
        openAlertDialog({
          title: t('dialogs.delete_meeting.title'),
          message: t('dialogs.delete_meeting.message'),
          onConfirm: (index: number) => {
            dispatch(deleteMeeting(id));
            dispatch(closeAlertDialog({ index }));
          },
        }),
      );
    },
    [dispatch, t],
  );
  return (
    <HStack
      borderColor={calendarMeeting?.color || '#AA915D'}
      borderRadius="20px"
      borderStyle="solid"
      borderWidth="1px"
      cursor="pointer"
      onClick={() => {
        dispatch(setCalendarEventDetailsDialog({ item: calendarMeeting }));
      }}
      w="100%"
      padding="14px 24px">
      <Flex flexDirection="column" alignItems="start" w="100%">
        <Flex gap="1px">
          <Text
            wordBreak="keep-all"
            noOfLines={1}
            variant="dashboardEventCardTitle"
            fontWeight="700">{`${startTime}-${endTime}`}</Text>
          <Text
            variant="dashboardEventCardTitle"
            wordBreak="break-all"
            noOfLines={1}>
            {meeting?.name || calendarMeeting?.summary}
          </Text>
        </Flex>
        {meeting?.location?.label ||
          (calendarMeeting?.location && (
            <HStack mt={0} ms={0}>
              <Icon as={IoLocationOutline} color="general.100" />
              <Text
                color="general.100"
                noOfLines={1}
                pt={0}
                fontWeight="400"
                fontSize={{ base: 'sm', sm: 'xs', '2xl': 'sm' }}
                wordBreak={'break-word'}>
                {meeting?.location?.label || calendarMeeting?.location}
              </Text>
            </HStack>
          ))}
      </Flex>
      <VStack alignSelf="end">
        <Avatars users={meeting?.guests} />
      </VStack>
      {isDelete && (
        <Icon
          as={FaX}
          color="#AA915D"
          w="10px"
          h="10px"
          cursor="pointer"
          onClick={() => onDeleteEvent(meeting?.id || calendarMeeting?.id)}
        />
      )}
    </HStack>
  );
}
