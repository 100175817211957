export const tabsStyles = {
  components: {
    Tabs: {
      variants: {
        task: () => ({
          root: {},
          tab: {
            fontSize: '14px',
            fontWeight: '700',
            lineHeight: '18.23px',
            letterSpacing: '-2%',
            opacity: 0.5,
            color: '#aa915d',
            padding: '0px',
            _selected: {
              opacity: 1,
              color: '#665738',
              boxShadow: 'none',
            },
            _active: {
              opacity: 1,
              color: '#665738',
              boxShadow: 'none',
            },
            _hover: {
              opacity: 1,
              color: '#665738',
              boxShadow: 'none',
            },
          },
          tablist: {
            justifyContent: 'space-between',
          },
          tabpanels: {},
          tabpanel: {},
        }),
      },
    },
  },
};
