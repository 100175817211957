import { useSelector } from 'react-redux';
import { RootState } from 'contexts/redux/store';
import {
  useColorModeValue,
  keyframes,
  usePrefersReducedMotion,
} from '@chakra-ui/react';
import { HighlightableComponent } from 'contexts/redux/uiHelper/uiHelperSlice';
import { useEffect, useRef } from 'react';

const highlight = keyframes`
  0%, 25% { transform: scale(1); box-shadow: 0 0 0 0 #86E5C650 }
  50% { transform: scale(1.02); box-shadow: 0 0 6px 6px #86E5C6; }
  85%, 100% { transform: scale(1); box-shadow: 0 0 0 0 #93C3B380; }
`;

export const useHighlightAnimation = (
  componentName: HighlightableComponent,
) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const prefersReducedMotion = usePrefersReducedMotion();
  const highlightedComponent = useSelector(
    (state: RootState) => state.uiHelper.highlightedComponent,
  );
  const highlightColor = useColorModeValue('gray.200', 'brand.800');

  const animation = prefersReducedMotion
    ? undefined
    : `${highlight} 2s ease-in-out infinite`;

  const isHighlighted = highlightedComponent === componentName;

  useEffect(() => {
    if (isHighlighted && componentRef.current) {
      componentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [isHighlighted]);

  return {
    style: isHighlighted
      ? {
          animation,
          backgroundColor: highlightColor,
          transition: 'all 0.3s ease-in-out',
        }
      : {
          transition: 'all 0.3s ease-in-out',
        },
    ref: componentRef,
  };
};
