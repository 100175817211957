export const AlertStyles = {
  components: {
    Alert: {
      variants: {
        green: () => ({
          container: {
            bg: '#5FBF96',
            color: 'white',
          },
        }),
        main: () => ({
          container: {
            bgGradient: {
              base: 'linear(to-b, brand.400, brand.600)',
              sm: 'linear(to-r, brand.400, brand.600)',
              md: 'linear(to-l, brand.400, brand.600)',
              lg: 'linear(to-l, brand.400, brand.600)',
              xl: 'linear(to-l, brand.400, brand.600)',
            },
            color: 'brand.300',
          },
        }),
        secondary: () => ({
          container: {
            bgGradient: {
              base: 'linear(to-b, brand.700, brand.900)',
              sm: 'linear(to-r, brand.700, brand.900)',
              md: 'linear(to-l, brand.700, brand.900)',
              lg: 'linear(to-l, brand.700, brand.900)',
              xl: 'linear(to-l, brand.700, brand.900)',
            },
            color: 'brand.300',
          },
        }),
        error: () => ({
          container: {
            bgGradient: {
              base: 'linear(to-b, red.400, red.600)',
              sm: 'linear(to-r, red.400, red.600)',
              md: 'linear(to-l, red.400, red.600)',
              lg: 'linear(to-l, red.400, red.600)',
              xl: 'linear(to-l, red.400, red.600)',
            },
            color: 'white',
          },
        }),
      },
    },
  },
};
