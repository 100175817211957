import {
  Button,
  Flex,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { HSeparator } from 'components/separator/Separator';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TheOffice from '../theOffice';
import { AddSeatModal } from '../theOffice/team/components/AddSeatModal';
import BusinessBanner from './banner';
import BusinessHeader from './header';
import useUpdateBusiness from './hooks/useUpdateBusiness';
import BusinessInfo from './info';
import BusinessSocials from './socials';
export default function BusinesSettings() {
  const [isEditModeEnabled, setIsEditModeEnabled] = useState<boolean>(false);
  const myBusiness = useMyBusiness();
  const {
    inputValues,
    handleChange,
    updateBusiness,
    loading,
    setInputValues,
    setLoading,
  } = useUpdateBusiness(myBusiness);

  const { t } = useTranslation();

  const handleUpdateBusiness = useCallback(async () => {
    const updated = await updateBusiness();
    if (updated) {
      setIsEditModeEnabled(false);
    }
  }, [updateBusiness]);
  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '190px', md: '80px', xl: '80px' }}>
      <Tabs isLazy={true} position="relative" variant="unstyled" w="100%">
        <TabList>
          <Tab
            _selected={{ color: 'black', boxShadow: 'none' }}
            color="gray.500">
            {t('business_settings.account_information')}
          </Tab>
          <Tab
            _selected={{ color: 'black', boxShadow: 'none' }}
            color="gray.500">
            {t('business_settings.the_office')}
          </Tab>
        </TabList>
        <TabIndicator
          mt="0px"
          height="4px"
          bg="brand.400"
          borderRadius="full"
        />

        <TabPanels pt={4}>
          <TabPanel key={'info'} as={AnimatePresence} mode="wait" w="100%">
            <Flex
              direction="column"
              width="stretch"
              pos="absolute"
              as={motion.div}
              initial={{ opacity: 0, scale: 0.98 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.98 }}>
              <Flex flexDirection="column" gap={6}>
                <Card
                  flexDirection="column"
                  w="100%"
                  p="0"
                  maxW="6xl"
                  borderRadius={{ base: 0, md: '30px' }}
                  role={!isEditModeEnabled ? 'group' : null}>
                  <BusinessHeader
                    isEditModeEnabled={isEditModeEnabled}
                    setIsEditModeEnabled={setIsEditModeEnabled}
                    updateBusiness={handleUpdateBusiness}
                    loading={loading}
                  />
                  <BusinessInfo
                    isEditModeEnabled={isEditModeEnabled}
                    inputValues={inputValues}
                    handleChange={handleChange}
                    myBusiness={myBusiness}
                    setInputValues={setInputValues}
                    setLoading={setLoading}
                  />
                  <HSeparator mt="39px" bg="rgba(0, 0, 0, 0.1)" />
                  <BusinessSocials
                    isEditModeEnabled={isEditModeEnabled}
                    inputValues={inputValues}
                    handleChange={handleChange}
                    myBusiness={myBusiness}
                  />
                  <Button
                    alignSelf="flex-end"
                    mx={{ base: '26px', md: '42px' }}
                    my={{ base: '26px', md: '20px' }}
                    display={isEditModeEnabled ? 'flex' : 'none'}
                    variant="h1cta"
                    w="-webkit-fill-available"
                    maxW={{ base: '100%', md: '171px' }}
                    onClick={handleUpdateBusiness}
                    isLoading={loading}
                    disabled={!isEditModeEnabled}>
                    {t('business_settings.save_changes')}
                  </Button>
                </Card>
                <Card flexDirection="column" w="100%" p="0" maxW="6xl">
                  <BusinessBanner
                    inputValues={inputValues}
                    handleChange={handleChange}
                    updateBusiness={handleUpdateBusiness}
                    setInputValues={setInputValues}
                    myBusiness={myBusiness}
                  />
                </Card>
              </Flex>
            </Flex>
          </TabPanel>
          <TabPanel key="office" as={AnimatePresence} mode="wait">
            <TheOffice
              pos="absolute"
              as={motion.div}
              initial={{ opacity: 0, scale: 0.98 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.15 }}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <AddSeatModal />
    </Flex>
  );
}
