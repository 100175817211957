import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { closeStatusChangeDialog } from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

export const StatusChangeModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dialog = useSelector(
    (state: RootState) => state.dialogs.statusChangeDialog,
  );
  const initialRef = useRef(null);

  const handleClose = useCallback(() => {
    dispatch(closeStatusChangeDialog());
  }, [dispatch]);

  const handleConfirm = useCallback(() => {
    if (dialog?.onConfirm) {
      dialog.onConfirm(dialog.item);
    }
    handleClose();
  }, [dialog, handleClose]);

  useEffect(() => {}, [dialog]);
  if (!dialog?.item) return null;

  return (
    <Modal
      isOpen={!!dialog}
      onClose={handleClose}
      isCentered
      initialFocusRef={initialRef}>
      <ModalOverlay />
      <ModalContent maxW="420px" borderRadius={'3xl'} pb={6}>
        <ModalHeader p={5}>
          <HStack justify="space-between">
            <VStack align="start" spacing={1}>
              <Text fontSize="2xl">{t('event.status_change.title')}</Text>
            </VStack>
            <ModalCloseButton position="unset" alignSelf="flex-start" />
          </HStack>
        </ModalHeader>
        <ModalBody px={5}>
          <VStack spacing={6} align="stretch">
            <Text fontSize="sm" fontWeight="700" opacity={0.8}>
              {t('event.status_change.subtitle_change_status', {
                status: t(`event.status.${dialog.item.newStatus}`),
              })}
            </Text>
            <Text color="gray.600" fontSize={'sm'}>
              {['completed', 'cancelled'].includes(dialog?.item?.newStatus)
                ? t('event.status_change.description_change_status_archived', {
                    status: t(`event.status.${dialog.item.newStatus}`),
                  })
                : ''}
            </Text>
            <Text fontSize="sm" opacity={0.8}>
              {t('event.status_change.description_change_status')}
            </Text>

            <VStack spacing={3} pt={4}>
              <Button
                variant="h1cta"
                w="full"
                ref={initialRef}
                onClick={handleConfirm}>
                {['completed', 'cancelled'].includes(dialog?.item?.newStatus)
                  ? t('event.status_change.change_to_archived')
                  : t('event.status_change.change_to', {
                      status: t(`event.status.${dialog.item.newStatus}`),
                    })}
              </Button>
              <Button
                variant="h4"
                textDecorationLine={'underline'}
                w="full"
                onClick={handleClose}>
                {t('event.status_change.keep_current_status')}
              </Button>
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
