import { AxiosInstance, AxiosResponse } from 'axios';
import { IMeeting, PaginatedResults } from './@types';
import { createAxiosInstance } from './axios.util';

class MeetingService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createMeeting(IMeetingRequest: IMeeting): Promise<IMeeting> {
    const response: AxiosResponse<IMeeting> = await this.api.post(
      '/meetings',
      IMeetingRequest,
    );
    return response.data;
  }

  public async getMeetings(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<IMeeting>> {
    const response: AxiosResponse<PaginatedResults<IMeeting>> =
      await this.api.get('/meetings', {
        params: queryParams,
      });
    return response.data;
  }

  public async getMeeting(id: string): Promise<IMeeting> {
    const response: AxiosResponse<IMeeting> = await this.api.get(
      `/meetings/${id}`,
    );
    return response.data;
  }

  public async updateMeeting(
    id: string,
    updates: Partial<IMeeting>,
  ): Promise<IMeeting> {
    delete updates.creatorUserId;
    delete updates.id;
    delete updates.createdAt;
    delete updates.updatedAt;
    delete updates.business;

    const response: AxiosResponse<IMeeting> = await this.api.patch(
      `/meetings/${id}`,
      updates,
    );
    return response.data;
  }

  public async deleteMeeting(id: string): Promise<void> {
    await this.api.delete(`/meetings/${id}`);
  }
}

const _IMeetingService = new MeetingService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _IMeetingService;
