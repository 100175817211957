// Chakra imports
import { Flex, Image } from '@chakra-ui/react';

// Custom components
import { useNavigate } from 'react-router-dom';
import BrilliantLogo from '../../../assets/img/sidebar/logo.png';

export function SidebarBrand(props: { mini: boolean; hovered: boolean }) {
  const navigate = useNavigate();
  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      w="100%"
      mt={'30px'}
      maxW="260px"
      cursor={'pointer'}
      onClick={() => navigate('/main/dashboard')}>
      <Image src={BrilliantLogo} alt="" w="200px" h="52px" />
    </Flex>
  );
}

export default SidebarBrand;
