import { Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { VSeparator } from 'components/separator/Separator';
import CreateMeetingContainer from './components/createMeetingContainer';
import { MeetingAgenda } from './components/MeetingAgenda';

export default function CreateMeeting() {
  return (
    <Flex
      w="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}
      pb={{ base: '100px', md: '0' }}
      flexDirection="column"
      gap={4}>
      <Card gap={5} w="100%" flexDirection="column" pb={5}>
        <Flex w="100%" direction={{ base: 'column', md: 'row' }}>
          <CreateMeetingContainer />
          <VSeparator
            bg="#00000033"
            w="1px"
            mx={{ base: 0, md: 4 }}
            display={{ base: 'none', md: 'flex' }}
          />

          <MeetingAgenda />
        </Flex>
      </Card>
    </Flex>
  );
}
