import { Flex, HStack } from '@chakra-ui/react';
import useGetEvent from 'queries/event';
import { useParams } from 'react-router-dom';
import Messages from '../managment/events/eventInfo/components/Messages';
import Tracker from '../managment/events/eventInfo/components/Tracker';
import { StatusChangeModal } from '../managment/events/eventsOverview/components/StatusChangeModal';
import { EventPageTopNavigationBar } from './components/EventPageTopNavigationBar';
import TaskList from './components/Tasks';
export const EventPage: React.FC = () => {
  const { id } = useParams();
  const { data } = useGetEvent(id);
  console.log(`event data ->`, data);
  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      height="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}>
      <Flex direction="column" height="100%" w="100%" gap={5}>
        <Flex gap={5} wrap={{ base: 'wrap', md: 'nowrap' }} w="100%">
          <EventPageTopNavigationBar event={data} />
        </Flex>
        <HStack
          display="flex"
          flexDirection={{ base: 'column', md: 'row' }}
          gap={2}
          h="100%">
          <TaskList />
          <Tracker />
          <Messages />
        </HStack>
      </Flex>
    </Flex>
  );
};
