import {
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import { User } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import AssignAutocompleteField from 'components/AssignAutocompleteField';
import AuthImage from 'components/authImage/AuthImage';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { openAlertDialog } from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMoreVertical } from 'react-icons/fi';
import { LuBan, LuFlag, LuTrash2 } from 'react-icons/lu';
import { useDispatch, useSelector } from 'react-redux';
import UserService from 'services/user.api';
import { ChatSearchBarComponent } from './ChatSearchBarComponent';

interface Props {
  currentAssignedInput: any;
  displayingAssigned: any;
  handleRemoveAssigned: any;
  handleSelectAssigned: any;
  setCurrentAssignedInput: any;
}

export default function ChatTopHeaderUserInfo(props: Props) {
  const {
    currentAssignedInput,
    displayingAssigned,
    handleRemoveAssigned,
    handleSelectAssigned,
    setCurrentAssignedInput,
  } = props;

  const { t } = useTranslation();
  const { newThread, currentThread } = useSelector((state: RootState) => ({
    newThread: state.messenger.newThread,
    currentThread: state.messenger.currentThread,
  }));
  const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const user = useMyUser();
  const dispatch = useDispatch<AppDispatch>();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const searchState = useSelector(
    (state: RootState) => state.messenger.searchState,
  );
  const participant = useMemo(
    () =>
      (currentThread?.participants as string[])?.find((p) => p !== user?.id) ||
      '',
    [currentThread?.participants, user?.id],
  );

  const participantResult = useQuery({
    queryKey: [`getParticipant-${participant}`],
    queryFn: () => UserService.getUser(participant),
    enabled: !!participant,
  });

  const assigned = useMemo(
    () =>
      (newThread?.participants as User[]) ||
      (participantResult.data && [participantResult.data]) ||
      [],
    [newThread?.participants, participantResult.data],
  );

  const handleDelete = useCallback(() => {
    dispatch(
      openAlertDialog({
        title: t('messenger.dialogs.delete.title'),
        content: t('messenger.dialogs.delete.description'),
        style: 'destructive',
        confirmLabel: t('messenger.dialogs.delete.confirm'),
        cancelLabel: t('dialogs.alert.cancel'),
        onConfirm: () => {
          // Handle delete action
          console.log('Delete conversation');
        },
      }),
    );
  }, [dispatch, t]);

  const handleBlock = useCallback(() => {
    dispatch(
      openAlertDialog({
        title: t('messenger.dialogs.block.title'),
        content: t('messenger.dialogs.block.description'),
        style: 'warning',
        confirmLabel: t('messenger.dialogs.block.confirm'),
        cancelLabel: t('dialogs.alert.cancel'),
        onConfirm: () => {
          // Handle block action
          console.log('Block user');
        },
      }),
    );
  }, [dispatch, t]);

  const handleReport = useCallback(() => {
    // Handle report action
  }, []);

  const renderSearchControls = useCallback(() => {
    if (!searchState.isSearchBarOpened && !searchState.isSearching) {
      // State 1: Only search icon and more menu
      return (
        <Flex gap={2} alignItems="center">
          <ChatSearchBarComponent />
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<FiMoreVertical />}
              w="34px"
              h="34px"
              color="#8E7A70"
              aria-label="more"
              variant="ghost"
            />
            <MenuList borderRadius="16px" p="8px">
              <MenuItem
                onClick={handleDelete}
                icon={<LuTrash2 size={18} />}
                borderRadius="8px"
                _hover={{ bg: 'gray.100' }}
                gap={2}>
                {t('messenger.menu.delete')}
              </MenuItem>
              <MenuItem
                onClick={handleBlock}
                icon={<LuBan size={18} />}
                borderRadius="8px"
                _hover={{ bg: 'gray.100' }}
                gap={2}>
                {t('messenger.menu.block')}
              </MenuItem>
              <MenuItem
                onClick={handleReport}
                icon={<LuFlag size={18} />}
                color="red.500"
                borderRadius="8px"
                _hover={{ bg: 'red.50' }}
                gap={2}>
                {t('messenger.menu.report')}
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      );
    }

    // State 2 & 3: Search bar is open or showing results
    return <ChatSearchBarComponent />;
  }, [
    searchState.isSearchBarOpened,
    searchState.isSearching,
    handleDelete,
    handleBlock,
    handleReport,
    t,
  ]);

  if (assigned.length > 0) {
    return (
      <HStack w="100%" minH={'44px'} justifyContent="space-between">
        <Flex
          gap="12px"
          flex={1}
          display={isMobile && searchState.isSearching ? 'none' : 'flex'}>
          <AuthImage
            path={
              assigned[0]?.media?.find((media: any) => media?.type === 'avatar')
                ?.url
            }
            isAvatar
            name={assigned[0]?.firstName + ' ' + assigned[0]?.lastName}
            w="32px"
            minW="32px"
            minH="32px"
            h="32px"
            borderRadius="50%"
          />
          <Flex flexDirection="column" justify="center">
            <Text
              lineHeight="16px"
              variant="detailInfoValue"
              color="#1B2037"
              noOfLines={1}
              maxW="200px">
              {`${assigned[0]?.firstName} ${assigned[0]?.lastName}`}
            </Text>
          </Flex>
        </Flex>
        {renderSearchControls()}
      </HStack>
    );
  }

  return (
    <AssignAutocompleteField
      labelProps={{ ms: '15px', mb: '0px' }}
      options={displayingAssigned}
      value={currentAssignedInput}
      borderRadius="full"
      h="44px"
      bg={inputBg}
      onSelected={handleSelectAssigned}
      selectedOptions={assigned.map((item: any) => item.id)}
      handleRemove={handleRemoveAssigned}
      onChange={(e) => setCurrentAssignedInput(e.target.value)}
      placeholder={t('messenger.chat.header.search_placeholder')}
      w="100%"
    />
  );
}
