// src/features/auth/authSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, UserWithTokens, SignUpUser } from 'services/@types';
import UserService from 'services/user.api';
import AuthService from 'services/auth.api';
import { useSelector } from 'react-redux';
import store, { RootState } from '../store';
import { closeSplashDialog, showSplashDialog } from '../dialog/dialogsSlice';
import { fetchBusiness } from '../business/businessSlice';
import { fetchBusinessTags } from '../businessTags/businessTagsSlice';
import { fetchBusinessCategories } from '../businessCategories/businessCategoriesSlice';
import { setCookie } from 'utils/cookie.util';
import { AxiosError } from 'axios';
import { getErrorMessageKey } from 'utils/getErrorMessageKey';
import { sendEvent } from 'utils/analytics.util';
import { GAEventAction, GAEventCategory, GAEventLabel } from 'types/ga-enums';

export const updateMyUser = createAsyncThunk(
  'auth/updateMyUser',
  async ({ updates }: { updates: Partial<User> }, { rejectWithValue }) => {
    console.debug('#Redux -> updateMyUser...', updates, store.getState());
    try {
      if (
        updates.onboardingStep === 'completed' &&
        store.getState().businesses?.business?.inviteToken
      ) {
        sendEvent(
          GAEventCategory.SupplierInvite,
          GAEventAction.SupplierInviteRegistered,
          GAEventLabel.SupplierInviteRegistered,
        );
      }
      store.dispatch(showSplashDialog({ title: 'dialogs.splash.please_wait' }));
      const userId = store.getState()?.auth?.user?.id;
      // delete updates.isEmailVerified;
      // delete updates.createdAt;
      const response = await UserService.updateUser(userId, updates);
      const { tokens } = JSON.parse(localStorage.getItem('token') || '{}');
      localStorage.setItem(
        'token',
        JSON.stringify({ user: response, tokens: tokens }),
      );
      store.dispatch(closeSplashDialog());
      return response;
    } catch (error) {
      store.dispatch(closeSplashDialog());
      return rejectWithValue(error);
    }
  },
);
export const loginWithToken = createAsyncThunk(
  'auth/loginWithToken',
  async ({ accessToken }: { accessToken: string }) => {
    const response = await AuthService.loginWithToken(accessToken);
    store.dispatch(fetchBusinessTags({}));
    store.dispatch(fetchBusinessCategories({ limit: 20 }));
    return response;
  },
);

export const fetchUser = createAsyncThunk(
  'auth/fetchUser',
  async (userId: string): Promise<User> => {
    const response = await UserService.getUser(userId);
    if (response?.businessID) {
      store.dispatch(fetchBusiness(response?.businessID));
    }
    const { tokens } = JSON.parse(localStorage.getItem('token') || '{}');
    localStorage.setItem(
      'token',
      JSON.stringify({ user: response, tokens: tokens }),
    );
    setCookie('auth', 'true', 90);
    setCookie('visited', 'true', 180);
    return response;
  },
);

export const signUp = createAsyncThunk(
  'auth/signUp',
  async (user: SignUpUser, { rejectWithValue }): Promise<User> => {
    try {
      const response = await AuthService.signup(user);
      return response;
    } catch (error) {
      const axiosError = error as AxiosError;
      throw rejectWithValue({ code: axiosError.response.status });
    }
  },
);
interface AuthState extends UserWithTokens {
  isAuthenticated: boolean;
  signingUp: boolean;
  error: string | null;
}

const initialState: AuthState = {
  user: null,
  tokens: null,
  isAuthenticated: false,
  signingUp: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSignupError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setCredentials: (state, action: PayloadAction<UserWithTokens>) => {
      console.debug('#Redux -> setCredentials...', action.payload);
      state.user = action.payload.user;
      state.tokens = action.payload.tokens;
      state.isAuthenticated = true;
    },
    clearCredentials: (state) => {
      console.debug('#Redux -> clearCredentials...');
      localStorage.removeItem('token');
      state.user = null;
      state.tokens = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateMyUser.fulfilled, (state, action) => {
        console.debug('#Redux -> updateUser.fulfilled...', action.payload);
        state.user = action.payload;
      })
      .addCase(updateMyUser.rejected, (state, action) => {
        console.error('#Redux -> updateUser.rejected...', action.error);
      })
      .addCase(updateMyUser.pending, (state) => {
        console.debug('#Redux -> updateUser.pending...');
      })
      .addCase(signUp.fulfilled, (state, action) => {
        console.debug('#Redux -> signUp.fulfilled...', action.payload);
        state.user = action.payload;
        state.signingUp = false;
        state.error = null;
      })
      .addCase(signUp.rejected, (state, action: any) => {
        console.error('#Redux -> signUp.rejected...', action.error);
        state.signingUp = false;
        if (typeof action?.payload?.code === 'number') {
          if (action.payload.code === 409) {
            state.error = 'signup.error';
          } else {
            state.error = getErrorMessageKey(action.payload.code);
          }
        }
      })
      .addCase(signUp.pending, (state) => {
        console.debug('#Redux -> signUp.pending...');
        state.signingUp = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        console.debug('#Redux -> fetchUser.fulfilled...', action.payload);
        state.user = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        console.error('#Redux -> fetchUser.rejected...', action.error);
      })
      .addCase(fetchUser.pending, (state) => {
        console.debug('#Redux -> fetchUser.pending...');
      });
  },
});

export function useMyUser() {
  const auth = useSelector((state: RootState) => state.auth);
  return auth.user;
}
export function useAuthToken() {
  const auth = useSelector((state: RootState) => state?.auth);
  return auth?.tokens?.access?.token;
}

export const { setCredentials, clearCredentials, setSignupError } =
  authSlice.actions;

export default authSlice.reducer;
