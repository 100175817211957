import { AxiosInstance, AxiosResponse } from 'axios';
import { PushNotificationToken } from './@types';
import { createAxiosInstance } from './axios.util';
export type PushNotificationTokenOmitted = Omit<
  PushNotificationToken,
  'createdAt' | 'updatedAt' | 'id'
>;
class PushNotificationTokenService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async sendPushNotificationToken(
    token: PushNotificationTokenOmitted,
  ): Promise<PushNotificationToken> {
    const response: AxiosResponse<PushNotificationToken> = await this.api.post(
      '/push-notification-tokens',
      token,
    );
    return response.data;
  }

  public async unregisterPushNotificationToken(
    id: string,
    token: string,
  ): Promise<void> {
    await this.api.delete(`/push-notification-tokens/${id}`);
  }
}

const pushNotificationTokenService = new PushNotificationTokenService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default pushNotificationTokenService;
