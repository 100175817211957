import { Flex, HStack, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { INotification } from 'services/@types';
import { getNotificationMomentFormat } from './NotificationComponent';
import 'moment/locale/he'; // Add Hebrew locale support

export default function PlainNotification({
  notification,
  notificationRef,
}: {
  notification: INotification;
  notificationRef: React.RefObject<HTMLDivElement>;
}) {
  const { t, i18n } = useTranslation();

  // Set moment locale based on current language
  moment.locale(i18n.language.startsWith('he') ? 'he' : 'en');

  return (
    <Flex
      ref={notificationRef}
      border="1px solid #F9FAFB"
      borderRadius="12px"
      p="8px"
      flexDirection="column">
      <HStack>
        <Text variant="cardSubTitle" color="#1B2037">
          {t(notification.message)}
        </Text>
      </HStack>
      <Text fontSize="md" color="gray.500" alignSelf="flex-end">
        {moment(notification.createdAt).format(
          getNotificationMomentFormat(i18n.language),
        )}
      </Text>
    </Flex>
  );
}
