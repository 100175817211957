import { AxiosProgressEvent } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Upload } from 'services/@types';
import _uploadService from 'services/upload.api';

type ProgressCallback = (progress: number) => void;
type Callback = (file: File, progress: number) => void;

interface UploadFileQueueItem {
  file: File;
  target: string;
  targetId: string;
  access: string;
  onProgress: ProgressCallback;
  onComplete: (upload: Upload, progress: number) => void;
  onError: Callback;
}

const useUploadFile = () => {
  const [queue, setQueue] = useState<UploadFileQueueItem[]>([]);

  const processQueue = useCallback(async () => {
    if (queue.length > 1) return;
    console.debug('use upload file start ->', queue[0]);
    const { file, onProgress, onComplete, onError, target, targetId, access } =
      queue[0];
    console.log(`queue ->`, queue);
    try {
      const upload = await _uploadService.uploadFile(
        target,
        targetId,
        access,
        file,
        (progressEvent: AxiosProgressEvent) => {
          console.debug(
            'use upload file progress ->',
            file.name,
            progressEvent.loaded,
            progressEvent.total,
          );
          onProgress(
            Math.round((progressEvent.loaded / progressEvent.total) * 100),
          );
        },
      );
      console.debug('use upload file complete ->', upload);
      onComplete(upload, 100);
    } catch (error) {
      console.error('use upload file error ->', error);
      onError(file, 0);
    } finally {
      setQueue((currentQueue) => currentQueue.slice(1));
    }
  }, [queue]);

  const uploadFile = useCallback(
    (
      file: File,
      target: string,
      targetId: string,
      access: string,
      onProgress: ProgressCallback,
      onComplete: (upload: Upload, progress: number) => void,
      onError: Callback,
    ) => {
      setQueue((currentQueue) => [
        ...currentQueue,
        { file, onProgress, onComplete, onError, target, targetId, access },
      ]);
    },
    [],
  );

  // Monitor and process the queue whenever it changes
  useEffect(() => {
    if (queue.length > 0) {
      processQueue();
    }
  }, [processQueue, queue.length]);

  return { uploadFile };
};

export default useUploadFile;
